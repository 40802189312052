export const Config ={
    HOST2: 'http://localhost:3000/',
    HOST:'http://vxdo.test-e5q.pages.dev/',
    API_HOST4: 'http://localhost:3001/api/',
    API_HOST2: 'https://testserv-xfkk.onrender.com/api/',
    API_HOST: 'https://jittery-ray-sundress.cyclic.app/api/',
    DEV: false,
    API_HOST3: 'https://ibapi-node.azurewebsites.net/api/',
    testing: true,
}
