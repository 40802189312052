import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { NativeBaseProvider, extendTheme } from "native-base";
import reportWebVitals from "./reportWebVitals";
import {AppProvider,useAppContext} from "./AppContextProvider";
import {SafeAreaProvider} from "react-native-safe-area-context";
import {QueryClient, QueryClientProvider} from "react-query";
//import Icon from 'react-native-vector-icons/dist/FontAwesome';
//import iconFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';

import Icon from 'react-native-vector-icons/dist/MaterialIcons';

import iconFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';

import Roboto_400Regular from '@expo-google-fonts/roboto/Roboto_400Regular.ttf';
import Roboto_300Light from '@expo-google-fonts/roboto/Roboto_300Light.ttf';
import Roboto_700Bold from '@expo-google-fonts/roboto/Roboto_700Bold.ttf';
import Roboto_500Medium_Italic from '@expo-google-fonts/roboto/Roboto_500Medium_Italic.ttf';
import Roboto_100Thin from '@expo-google-fonts/roboto/Roboto_100Thin.ttf';
import Roboto_300Light_Italic from '@expo-google-fonts/roboto/Roboto_300Light_Italic.ttf';
import Roboto_900Black_Italic from '@expo-google-fonts/roboto/Roboto_900Black_Italic.ttf';
import Roboto_100Thin_Italic from '@expo-google-fonts/roboto/Roboto_100Thin_Italic.ttf';
import Roboto_500Medium from '@expo-google-fonts/roboto/Roboto_500Medium.ttf';
import Roboto_700Bold_Italic from '@expo-google-fonts/roboto/Roboto_700Bold_Italic.ttf';
import Roboto_900Black from '@expo-google-fonts/roboto/Roboto_900Black.ttf';
import Roboto_400Regular_Italic from '@expo-google-fonts/roboto/Roboto_400Regular_Italic.ttf';



const queryClient = new QueryClient()
// Use prebuilt version of RNVI in dist folder


const iconFontStyles0 = `
@font-face {
  src: url(${iconFont});
  font-family: FontAwesome;
}


@font-face {
  src: url(${Roboto_400Regular});
  font-family: "Roboto_400Regular";
}

@font-face {
  src: url(${Roboto_300Light});
  font-family: "Roboto_300Light";
}

@font-face {
  src: url(${Roboto_700Bold});
  font-family: "Roboto_700Bold";
}

@font-face {
  src: url(${Roboto_500Medium_Italic});
  font-family: "Roboto_500Medium_Italic";
}

@font-face {
  src: url(${Roboto_100Thin});
  font-family: "Roboto_100Thin";
}

@font-face {
  src: url(${Roboto_300Light_Italic});
  font-family: "Roboto_300Light_Italic";
}

@font-face {
  src: url(${Roboto_900Black_Italic});
  font-family: "Roboto_900Black_Italic";
}

@font-face {
  src: url(${Roboto_100Thin_Italic});
  font-family: "Roboto_100Thin_Italic";
}

@font-face {
  src: url(${Roboto_500Medium});
  font-family: "Roboto_500Medium";
}

@font-face {
  src: url(${Roboto_700Bold_Italic});
  font-family: "Roboto_700Bold_Italic";
}

@font-face {
  src: url(${Roboto_900Black});
  font-family: "Roboto_900Black";
}

@font-face {
  src: url(${Roboto_400Regular_Italic});
  font-family: "Roboto_400Regular_Italic";
}



`;
const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: MaterialIcons;
}`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
} else {
    style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);


ReactDOM.render(
  <React.StrictMode>
          <QueryClientProvider client={queryClient}>
    <AppProvider>

      <App />
    </AppProvider>
          </QueryClientProvider>

  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
