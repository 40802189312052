import {Platform} from 'react-native';
import { unstable_createElement as createElement } from "react-native";
import { View,Box } from 'native-base'
const VideoPlayerNative=({url})=>{


}

export const WebVideo = (props) => {
    //console.log('height',props.height)
    const attrs = {
       style: {border: "none",height: props.height+'px'},
        onLoad: function(e){
          console.log('loaded',e.target.offsetWidth)
        },
        src: props.source,
       // loading:"lazy",
        allow:"accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;",
//        poster: props.poster,
        controls: "controls"
    }
    return createElement("iframe", attrs)
}
const VideoPlayer=({url,height})=>{
return     <WebVideo
        source={url} height={height}

/>


}
export  default Platform.OS==='web'? VideoPlayer: VideoPlayerNative
