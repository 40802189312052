import React, {useContext, useEffect, useState} from "react";
import axios from 'axios';
import {useQuery} from "react-query";
import {handleError, MyStorage, parseQueryString} from "./Functions";
import links from './content/links.json'
import {Config} from "./Config";

import treatMentList from './content/list.json'
//const videoUrl = 'https://video.bunnycdn.com/embed/435/a980133a-1f98-4efe-9b9e-f31e9be25744?autoplay=true'
const actionStacks = []
const activeStacks = []
const max_attempts = 3
const max_depth = 5
const treatmentStacks = []
const treatMentList1 = {
    "input": {
        "name": "Input",
        "header": "Input your problem",
        "process": "input",
        "treatmentHeader": "Enter problem name",
        "treatmentDescription": "",

        "video": "https://video.bunnycdn.com/embed/435/a980133a-1f98-4efe-9b9e-f31e9be25744?autoplay=true",
        "images": [
            "http://i.imgur.com/XP2BE7q.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },


    "tap": {
        "name": "Tap ",
        "header": "Problem name: This %s",
        "process": "Taps",
        "treatmentHeader": "Tapping sequence ",
        "treatmentDescription": "Please tap your body on the areas shown in the image and the video about seven times.",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/XP2BE7q.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },
    "samename": {
        "name": "SameName",
        "header": "Same Problem name: This %s",
        "process": "SameName",
        "treatmentHeader": "Same problem name? ",
        "treatmentDescription": "Please tell if same problem name",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/XP2BE7q.jpg",
            'http://i.imgur.com/XP2BE7q.jpg'
        ]
    },

    "etap": {
        "name": "Extended Tap",
        "header": "Problem name: This %s",
        "process": "Impediments",
        "subprocess": "ExtendedTap",
        "treatmentHeader": "Try Extended Tap",
        "treatmentDescription": "Please tap your body on the areas shown in the image and the video about seven times.",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },

    "gamut": {
        "name": "9 Gammut",
        "header": "Problem name: This %s",
        "process": "Impediments",
        "subprocess": "Gamut",
        "treatmentHeader": "9 Gamut",
        "treatmentDescription": "Please constantly tap your hand on the area shown in the image and the video while carrying out the other actions requested until you have completed those actions.",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },
    "psyrev": {
        "name": "Psy Rev",
        "header": "Problem name: This %s",
        "process": "Impediments",
        "subprocess": "PsyRev",
        "treatmentHeader": "Psychological Reversal",
        "treatmentDescription": "Sometimes there is a block in the so-called energy flow which can be unblocked by carrying out this procedure",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },
    "tat": {
        "name": "Tat",
        "header": "Problem name: This %s",
        "process": "Impediments",
        "subprocess": "Tat",
        "treatmentHeader": "Tat",
        "treatmentDescription": "Please put your hands in the position shown and concentrate on the emotional disturbance that your s% generates, until it changes in Position (if it's a physical pain), Intensity or Nature.",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },
    "stuck": {
        "name": "Stuck",
        "header": "Problem name: This %s",
        "process": "Stuck",
        "subprocess": "",
        "treatmentHeader": "This %s stuck at %i",
        "treatmentDescription": "Sometimes solving the problem is hindered by unconscious aspects which can be dealt with by woking on the idea of being stuck.",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },

    "aspect": {
        "name": "Aspect",
        "header": "Problem name: This %s. Are you sure/underlying problem?",
        "process": "Aspect",
        "subprocess": "",
        "treatmentHeader": "Aspect of %s currently at %i, Are you sure / Underlying issue?",
        "video": "https://videourl",
        "treatmentDescription": "If you still haven't reduced the SUDS value then it is likely that there are some aspects of the issue that are hindering the solution of your s%. If the problem is a physical pain then think about any other aspect like some undelying nuisance, like a throbbing feeling or a stabbing feeling or dullness etc. If the problem is an emotional issue then try and extract the most important aspect of that, for example if you're anxious, maybe your anxiety is not being reduced because your anxiety is partly driven by a specific fear.",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },


    "collarbone": {
        "name": "Collar bone",
        "header": "Problem name: This %s",
        "process": "Impediments",
        "subprocess": "CollarBoneEx",
        "treatmentHeader": "CollarBone Exercise",
        "treatmentDescription": "This is a rare exercise to carry out but sometimes it is very effective when all else fails to budge the SUDS value",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },
    "changepos": {
        "name": "Change Position",
        "header": "Problem name: This %s",
        "process": "Impediments",
        "subprocess": "ChangePosition",
        "treatmentHeader": "Change Position",
        "treatmentDescription": "Sometimes electromagnetic radiation might prevent you from getting relief. Try moving away from any devices tapping on that issue and see if the SUDS changes.",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },
    "shower": {
        "name": "Shower",
        "header": "Problem name: This %s",
        "process": "Impediments",
        "subprocess": "Shower",
        "treatmentHeader": "Shower",
        "treatmentDescription": "Sometimes certain cosmetics or clothes might prevent you from getting relief. Try showering with just water from any devices tapping on that issue and see if the SUDS changes.",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },
    "fast": {
        "name": "Wait Fast",
        "header": "Problem name: This %s",
        "process": "Impediments",
        "subprocess": "WaitFast",
        "treatmentHeader": "Wait and Fast",
        "treatmentDescription": "Sometimes a food intolerance might prevent you from getting relief. Try fasting and then try tapping on that issue and see if the SUDS changes. If that works do this treatment without eating any of what you ate on the day your SUDS value remained blocked.",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },

    "ghostbuster": {
        "name": "GhostBuster",
        "header": "Contact us for a session on %s",
        "process": "GhostBuster",
        "subprocess": "",
        "treatmentHeader": "Therapist required",
        "treatmentDescription": "Given that you have reached this point without reducing your SUDS, we suggest you contact us for an appointment by completing the form below.",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },

    "suds": {
        "name": "SUDS",
        "header": "Problem Rating",
        "process": "Suds",
        "subprocess": "",
        "treatmentHeader": "Evalulate issue intensity",
        "treatmentDescription": "Please evaluate on a scale from 0 to 10 the intensity of the disturbance that your s% causes you to feel. Try not to select the 10-100 scale if possible or you may lengthen the time it takes to solve your issue.",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",
            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },
    "confirm": {
        "name": "SUDS 0 Confirm",
        "header": "Problem Rating",
        "process": "confirm",
        "subprocess": "",
        "treatmentHeader": "Confirm 0 SUDS",
        "treatmentDescription": "Great! So your %s is at 0 SUDS, is your %s actually gone completely or is there still some undelying issue? For example if it was a pain, is there some numbness or other discomfort still? If it is an emotional issue, do you feel completely free of it? If yes, confirm, otherwise don't.",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",
            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },
    "report": {
        "name": "Report",
        "header": "Report of problem",
        "process": "Finish",
        "subprocess": "",
        "treatmentHeader": "",
        "treatmentDescription": "This far applies when incomplete",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },

    "complete": {
        "name": "Complete",
        "header": "This far we have got",
        "process": "Finish",
        "subprocess": "",
        "treatmentHeader": "Return to complete",
        "treatmentDescription": "Why would there be any remaining problem? Perhaps there would be a completely new problem to select at this point.",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    },
    "finish": {
        "name": "Finish",
        "header": "Congrats Problem is solved",
        "process": "Finish",
        "subprocess": "",
        "treatmentHeader": "This far we done(when this far display)",
        "treatmentDescription": "",
        "video": "https://videourl",
        "images": [
            "http://i.imgur.com/6vOahbP.jpg",

            'http://i.imgur.com/XP2BE7q.jpg'
        ]

    }
}


class Treatment {
    constructor() {
        this.name = ''
        this.attempt = 0
        this.code = 1
        this.depth = 0
        this.suds = -1
        this.prevName = ''
        this.prevSuds = -1
        this.ptr = 0
        this.startsuds = -1
        this.endsuds = 0
        this.maximpediment = 0
        this.maximpedimentname = ''
        this.roundcount = 0

    }
}


const cloneT1 = (obj) => {

    return JSON.parse(JSON.stringify(obj))
}

class TreatmentProcess {
    constructor() {
        this.level = 0
        this.expect = false
        this.step = ''
        this.newsuds=0
        this.log2 = () => {
        } //console.log
        this.treatment = new Treatment()
        this.round = 0
        this.prevsud = -1
        this.samename = true
        this.h1 = false
        this.treatments = [this.treatment]
        this.h2 = false
        this.impediment = 0
        this.aspect = []
        this.roundcount = 0
        this.attempt = 0
        this.extra = ''
        this.zerosuds = false

    }

    static fromJSON(jsonData) {
        const process = new TreatmentProcess();
        // Customize the deserialization logic here
        // For example, you can set properties based on the deserialized data
        Object.assign(process, jsonData);

        /*
         process.level = jsonData.level;
         process.expect = jsonData.expect;
         process.step = jsonData.step;
         process.round = jsonData.round;
      */

        // Continue setting other properties as needed
        return process;
    }

    toJSON() {
        const filteredProps = Object.keys(this).reduce((acc, key) => {
            if (typeof this[key] !== 'function') {
                acc[key] = this[key];
            }
            return acc;
        }, {});
        return filteredProps;
    }

    reinit() {
        this.level = 0
        this.step = ''
        this.prevsud = -1
        this.treatment = new Treatment()
        this.round = 0
        this.samename = true
        this.h1 = false
        this.treatments = [this.treatment]
        this.h2 = false
        this.impediment = 0
        this.aspect = []
        this.roundcount = 0
        this.attempt = 0
        this.extra = ''
    }

    isImpediment(level) {

        return [4, 5, 6, 7, 10, 11, 12, 13, 14].includes(level)

    }


    leveltoStep(level) {
        switch (level) {

            case 0:
                return 'input'
            case 1:
                return 'suds'
            case 2:
                return 'tap'
            case 3:
                return 'samename'

            case 4:
                return 'impediment'
            /*
                case 5:
                    return 'gamut'
                case 6:
                    return 'psyrev'
                case 7:
                    return 'tat'
                case 8:
                    return 'stuck'
                case 9:
                    return 'aspect'
                case 10:
                    return 'collarbone'
                case 11:
                    return 'changepos'
                case 12:
                    return 'shower'
                case 13:
                    return 'fast'
              */

            case 14:
                return 'ghostbuster'


            case 15:
                return 'thisfar'
            case 16:
                return 'congrat'
            case 17:
                return 'report'
            case 18:
                return 'donation'
            case 19:
                return 'end'
            case 20:
                return 'completebtn'
            default:
                return ''


        }
    }


    log(str) {
        //    console.log(str)
    }

    aspectBlock(data, out) {

        this.log2('aspectblockdata', this.treatment, 'level', this.level, 'stage', this.stage, 'expect', this.expect, 'data', JSON.stringify(data), 'trt', this.treatment)

        if ( !this.expect) {

            this.expect = true

            return this.output(out, 'aspect')

        }
        if (data['sure'] === 'no') {
            this.expect = false
            this.zerosuds = false
            if (this.aspect.length === 0) {
                this.level = 16 ///congrats
                return false
            } else {
                this.zerosuds = false
                this.treatment = this.aspect.pop()
                this.attempt = this.treatment.attempt
                this.impediment = this.treatment.impediment
                this.roundcount = this.treatment.roundcount
                this.newsuds = this.treatment.suds
                data['from_aspect'] = 1//name
                this.level = 1
                return false
            }
        } else if (data['samename'] === 'no' || data['sure'] === 'yes') {
            this.expect = false
            data['samename'] = ''
            data['from_aspect'] = false
            if (this.aspect.length < max_depth) {
                this.treatment.roundcount = this.roundcount
                this.prevsud = this.treatment.suds
                this.treatment.endsuds = this.prevsud
                if(this.zerosuds){ ///addsudscor
                    this.treatment.suds = 0;

                    this.zerosuds = false;    
                }
                this.aspect.push(this.treatment)
                data['from_push_aspect'] = true
                this.level = 0
                return false
            } else {
                this.zerosuds = false
                data['from_aspect'] = true
                this.impediment = 7;                
               
                this.level = 4
                return false

            }


        } else {
            this.log2('nodata', this.level, 'stage', this.stage, 'treat', this.treatment, 'imped', this.impediment)
        }


    }

    impedimentBlock(data, out) {

        this.log2('impedimentdata1', this.impediment, JSON.stringify(data), 'expect', this.expect, 'stage', this.stage, 'level', this.level, 'treatment', JSON.stringify(this.treatment))
        if (!this.expect) {
            this.expect = true
            data['samename'] = ''
            let process = ''

            this.log2('impedimentdata1', JSON.stringify(data), 'expect', this.expect)

            switch (this.impediment) {

                case 1:
                    process = 'etap';
                    break;
                case 2:
                    process = 'gamut';
                    break;
                case 3:
                    process = 'psyrev';
                    break;
                case 4:
                    process = 'tat';
                    break;
                case 5:
                    process = 'stuck';
                    break;
                case 6:
                    this.expect = false
                    if (this.treatment.maximpediment < 6) {
                        this.treatment.maximpediment = 6
                        this.treatment.maximpedimentname = 'aspect'
                    }

                    this.level = 5  //expect is true
                    return false

                case 7:
                    process = 'collarbone';
                    break;
                case 8:
                    process = 'changepos';
                    break;
                case 9:
                    process = 'shower';
                    break;
                case 10:
                    process = 'fast';
                    break;

                default:
                    this.expect = false
                    this.level = 14
                    if (this.treatment.maximpediment < 11) {
                        this.treatment.maximpediment = 11
                        this.treatment.maximpedimentname = 'ghostbuster'
                    }

                    return false

            }
            if (this.treatment.maximpediment < this.impediment) {
                this.treatment.maximpediment = this.impediment
                this.treatment.maximpedimentname = process
            }
            this.expect = true
            return this.output(out, process)

        } else {


            this.expect = false

            if (this.stage === 'etap') {
                this.level = 1;
                this.roundcount++
                this.treatment.roundcount = this.roundcount
                return false
            }

            this.level = 2
            return false


        }

    }


    run(data, out) {

        if (this.expect && data['callghost'] === 1) {

            this.expect = false
            this.level = 14
        }

        if (this.level === 0) { //level0

            if (!this.expect) {
                out['process'] = 'input'
                this.treatment = new Treatment()
                this.h1 = false
                /*
                this.treatment.attempt = this.attempt
                this.treatment.impediment = this.impediment
                this.treatment.depth = this.aspect.length
                */
                this.treatments.push(this.treatment)
                ///this.impediment = 0
                this.round = true
                this.expect = true
                this.stage = 'input'
            if(data['from_push_aspect']) {
                const t = this.aspect[this.aspect.length - 1];
                this.roundcount = t.roundcount
                this.attempt = t.attempt
                this.impediment = t.impediment
                this.treatment.suds = this.prevsud
                this.treatment.maximpediment = t.maximpediment
                this.treatment.maximpedimentname = t.maximpedimentname
                data['from_push_aspect']=false
                console.log('from_push_aspect')
            }

                this.treatment.roundcount = this.roundcount
                this.treatment.attempt = this.attempt
                this.treatment.impediment = this.impediment
                this.treatment.depth = this.aspect.length



                return this.output(out, 'input')
            } else {
                this.expect = false
                const name = data['name']
                this.name = name
                this.treatment.name = this.name
                this.level = 1
                return false;
            }
        }
        if (this.level === 1) {
            if (!this.expect) {
                if (!data['from_aspect']) {
                    if (!!!this.round) {
                        this.log('Round1==false')
                        this.h1 = true //remaining
                    } else {
                        this.log('Round1==True')
                    }
                    if (this.impediment === 5) {
                        this.h2 = true
                        this.log('impediment==5, stuck')
                    }
                    this.round = false
                }else{



                }


                this.expect = true
                return this.output(out, 'suds')

            } else {
                this.expect = false
                const suds = parseFloat(data['suds'])
                this.log('input suds ' + suds)
                if (this.treatment.startsuds === -1) {
                    this.treatment.startsuds = suds
                }
                //d4

                this.newsuds = suds
                if (suds > 0) {
                    //d5
                    if (suds !== this.suds) {
                        this.log('***SUCCESS d4:d5')
                        this.treatment.suds = suds
                        this.treatment.attempt = 0
                        this.attempt = 0
                        this.suds = suds ///addsudscor
                        this.impediment = 0
                        this.treatment.impediment = 0
                        this.level = 2 //tap
                        return false

                    }///d5
                    else {

                        this.attempt++
                        this.treatment.attempt = this.attempt
                        //d6

                        if (this.attempt >= 3) {

                            this.log('ATTEMPT>=3 d6>' + this.attempt + ' at suds ' + suds)
                            this.level = 3 //samename
                            return false
                        }///d6
                        else {
                            this.log('ATTEMPTL<3 d6>' + this.attempt + ' ' + suds)
                            this.level = 2 //tap
                            return false

                        }

                        //ed6


                    }///ed5


                } //suds<>0
                else {

                    data['from_suds'] = 1
                    this.zerosuds=true///addsudscor
                    this.newsuds = 0
                    ///this.treatment.suds = 0///addsudscor
                    //aspect
                    this.level = 5

                    return false

                }

            }//output d4 inputsuds


        }//level1
        if (this.level === 2) { //tap
            if (!this.expect) {

                this.expect = true

                return this.output(out, 'tap')


            } else {
                this.roundcount++
                this.treatment.roundcount = this.roundcount
                this.expect = false
                this.level = 1
                return false
            }


        }

        if (this.level === 3) {//samename
            if (!this.expect) {

                this.expect = true
                return this.output(out, 'samename')

            } else {
                this.expect = false

                if (data['samename'] === 'no') {
                    this.expect = true
                    this.level = 5
                    return false
                }else{

                    if(this.impediment !== 6){
                        this.impediment++;
                    }
               
                this.level = 4
                return false


                }
                          }

        }
        if (this.level === 4) {


            return this.impedimentBlock(data, out)


        }
        if (this.level === 5) {
            return this.aspectBlock(data, out)

        }
        if (this.level === 14) {
            if (!this.expect) {
                this.expect = true
                this.treatment.maximpediment = 14

                this.treatment.maximpedimentname = 'ghostbuster'
                return this.output(out, 'ghostbuster')
            } else {
                this.expect = false
                //process ghostbustform
                out.sendreport = true
                this.level = 20
                /*
                if (data['complete'] === 'yes') {
                    data['from_ghost'] = 1

                    this.level = 1
                    return false
                } else {

                    data['from_ghost'] = 1

                    data['thisfar'] = 1
                    this.level = 17 //report

                    return false


                }
                */
                return false

            }


        }

        if (this.level === 20) {//returntocomplete

            if (!this.expect) {
                this.expect = true


                return this.output(out, 'complete')
            } else {
                this.expect = false
                if (data['complete'] === 'no') {
                    data['thisfar'] = true
                    this.level = 17
                    return false
                } else {
                    data['thisfar']=false
                    this.level = 1
                    return false


                }


            }
        }//20


        if (this.level === 15) {//thisfar

            if (!this.expect) {


                out['thisfar'] = true
                this.level = 17
                return false
            }


        }
        if (this.level === 16) {//congrat

            if (!this.expect) {


                out['congrat'] = true
                this.level = 17
                return false
            }


        }
        if (this.level === 17) {
            if (!this.expect) {

                this.expect = true

                out['thisfar'] = ''
                if (data['thisfar']) {
                    out['thisfar'] = true
                }
                out['stack'] = this.treatments
                return this.output(out, 'report')

            } else {

                this.expect = false


            }

        }


    }


    initout() {

        const out = {
            'treatment': this.treatment,
            'stack': {},
            'aspect': {},
            id: new Date().getTime(),
            back: this.back,
            'header': '',
            header2: '',
            video: '',
            suds: this.treatment.suds
        }
        return out;
    }

    output(data, process) {

        data['impediment'] = this.impediment
        data['roundcount'] = this.roundcount
        data['attempt'] = this.attempt

        data['process'] = process
        this.stage = process
        data['video'] = treatMentList[this.stage]['video']
        data['videoTitle'] = treatMentList[this.stage]['videoTitle'] ?? 'Video Description'
        data['imgTitle'] = treatMentList[this.stage]['imgTitle'] ?? 'Image Description'
        data['problem'] = this.treatment.name

        data['images'] = treatMentList[this.stage]['images']
        data['header'] = treatMentList[this.stage]['header'].replace('%s', this.treatment.name).replace('%i', this.treatment.suds)
        data['treatmentHeader'] = treatMentList[this.stage]['treatmentHeader'].replace('%s', this.treatment.name).replace('%i', this.newsuds) //addsudscur
        data['treatmentDescription'] = treatMentList[this.stage]['treatmentDescription']
        data['round'] = this.round
        data['suds'] = this.suds
        data['treatment'] = this.treatment

        if(data['thisfar']&&this.level === 17){
           data['header'] = treatMentList['report']['treatmentDescription']
            data['thisfar']=false
        }
        if(this.level === 17){

        }else {
            data['h1'] = this.h1
        }
            // if (this.h2) {
         //   const suds = this.treatment.suds === -1 ?? this.prevsud
           // if (suds > -0) {
             //   data['header'] = data['header'] + this.tr('Stuck at ') + suds
           // }
        //}

        this.treatments.forEach(tr => data['stack'][tr.name] = tr.suds)

        this.aspect.forEach(tr => data['aspect'][tr.name] = tr.suds)
        if (this.treatment.name) {
            data['aspect'][this.treatment.name] = this.treatment.suds
        }


        return data

    }

    tr(x) {

        return x;
    }


}

class User {

    constructor() {
        this.name = 'test name'
        this.age = 18
        this.password = 'dddd'
        this.email = 'dd@dd.com'

    }

    login(un, pw) {
        if (un === this.email && pw === this.password) {
            return true
        }
        return false
    }

    edit(data) {
        this.password = data['password']
        this.name = data['name']
    }
}

const process = new TreatmentProcess()
const user = new User()
const AppContext = React.createContext()

const axiosInstance =
    axios.create({
        baseURL: Config.API_HOST,
        headers: {"Content-Type": "application/json"}
    });
 
const fetchText = async (section) => {
    const {data} = await axiosInstance.get('/text?section=' + section)
    return data
}

const useText = (section) => useQuery(['section', section], () => fetchText(section), {
    cacheTime: 1000000,
})
const isNativeApp = () => {

    return /pigig/.test(navigator.userAgent);
}
const AppProvider = ({children}) => {

    const [texts, setTexts] = useState({
        'treatment': {
            'problem': 'I am having %s today',

        }
    })

    const [state, dispatch] = React.useReducer(
        (prevState, action) => {
            switch (action.type) {
                case 'LINKS':
                    return {

                        ...prevState, ...action.data
                    }
                case 'ASPECT':
                    return {
                        ...prevState, ...action.data
                    }
                case 'VIDEO':
                    return {
                        ...prevState, ...action.data
                    }

                case  'IMAGE':
                    return {
                        ...prevState, ...action.data
                    }

                case 'PROCESS_REINIT':
                    return {
                        ...prevState,
                        treatmentData: {
                            ...prevState.treatmentData,
                            ...action.data
                        }

                    }


                case 'PROCESS_TREATMENT':
                    return {
                        ...prevState,
                        treatmentData: {...prevState.treatmentData, ...action.data}

                    }
                case 'PROFILE':
                    return {
                        ...prevState,
                        user: {...prevState.user, ...action.user}
                    }
                case 'LOADED':
                    return {...prevState, isLoading: false}
                case 'ERROR':
                    return {...prevState, error: action.error}

                case 'RESTORE_TOKEN':
                    return {
                        ...prevState,
                        userToken: action.data.user._id,
                        user: action.data.user,
                        isLoading: false,
                    };
                case 'SIGN_IN':
                    return {
                        ...prevState,
                        isLoading: false,
                        isSignout: false,
                        userToken: action.token,
                        user: action.user
                    };
                case 'SIGN_OUT':
                    return {
                        ...prevState,
                        isLoading: false,
                        isSignout: true,
                        userToken: null,
                        user: null
                    };
                    default:
                        return prevState;
            
            }
        },
        {
            videoUrl: '',
            imgUrl: '',
            links: [],
            isLoading: true,
            isSignout: false,
            userToken: null,
            user: null,
            aspect: [],
            treatmentData: {
                aspect: [],
                attempt: {},
                round: 0,
                process: '',
                subprocess: '',
                mainprocess: '',
                problem: '',


            },
            stack: [],
        }
    );

    const auth = React.useMemo(
        () => ({

            gotMessage: async (data) => {


            },

            forGot: async (data) => {
                return axiosInstance.post('/forgot', data).then(async ({data}) => {
                    return data
                }).catch((err) => {
                    ///throw err
                    handleError(err)
                    ///  //#console.log#'error in login')
                }).finally(() => {

                })


            },
            setProfile: async (data) => {
                return axiosInstance.post('/profile', data).then(async ({data}) => {
                    dispatch({type: 'PROFILE', user: data});
                    return data

                }).catch((err) => {
                    ///throw err
                  console.log('error',err)
                    handleError(err)
               ///     throw err
                    ///  //#console.log#'error in login')
                  //  return err
                })

            },

            signIn: async (data) => {

                const token = btoa(data.username + ':' + data.password)
                data._id = token
                /// axiosInstance.defaults.headers.common['Authorization'] = 'Basic '+token;
                //#console.log#'token',token);
                return axiosInstance.post('/login', data).then(async ({data}) => {
                    console.log('received',data)
                    const token = data._id

                    axiosInstance.defaults.headers.common['authorization'] = token
                    await MyStorage.setItem('userToken', token)
                    return data

                }).catch((e) => {
                    console.log('loginerr',e)
                    ///throw 'invalid login'
                    handleError(e)
                }).finally(() => {

                })


            },
            signOut: async () => {


                axiosInstance.defaults.headers.common['authorization'] = ''
                await MyStorage.removeItem('userToken')
                dispatch(
                    {type: 'SIGN_OUT'})
            },
            signUp: async (data) => {
                return axiosInstance.post('/signup', data).then(async ({data}) => {
                   /// dispatch({type: 'PROFILE', user: data});
                    return data

                }).catch((err) => {
                    console.log('error ',err)
                        handleError(err)

                    ///  //#console.log#'error in login')
                }).finally(() => {

                })

   ///             dispatch({type: 'SIGN_IN', token: data});

            },
        }),
        []
    );

    useEffect(() => {
        const bootstrapAsync = async () => {
            let userToken;

            try {
                userToken = await MyStorage.getItem('userToken');
            } catch (e) {
                // Restoring token failed
            }
            if (userToken) {

                axiosInstance.defaults.headers.common['authorization'] = userToken


                axiosInstance.post('user', {}).then(async ({data}) => {
                    ///userToken = data._id
//                        console.log('got user ',user)
                    dispatch({type: 'RESTORE_TOKEN', data: {user: data}});
                }).catch(async () => {

                    await MyStorage.setItem('userToken', '')
                    dispatch({type: 'SIGN_OUT'});
                }).catch(() => {
                }).finally(() => {

                })
            } else {
                dispatch({type: 'LOADED'})
            }
            // After restoring token, we may need to validate it in production apps

            // This will switch to the App screen or Auth screen and this loading
            // screen will be unmounted and thrown away.
            /*
                         dispatch({ type: 'RESTORE_TOKEN', token: userToken,user:{
                             email: 'dd@dd.com',
                             _id: userToken,
                             firstName: 'ff',
                             lastName: 'nn',
                             age:20,
                             obj: null
                             } });*/
        };

        bootstrapAsync();


    }, [])
    useEffect(() => {

    }, [state.userToken])

    return <AppContext.Provider value={{auth: auth, ...state, dispatch}}>{children}</AppContext.Provider>
}
const useAppContext = () => {
    const context = useContext(AppContext)
    if (context === null) {
        throw new Error('No context')
    }
    return context
}
const createTreatmentPost = async (treatment) => {
//    console.log('axiosinstance', axiosInstance.defaults)

    try {
//  //#console.log#'creating treatment',treatment)
        const {data, status} = await axiosInstance.post('treatment/push', treatment)
        if (status !== 200){
            throw 'Invalid response'
        }

        return data
    }catch (e) {
       throw 'Invalid data'
    }
}
//#console.log#'exporting')
export {AppProvider, useAppContext, axiosInstance, useText, createTreatmentPost}

