import React, {useEffect, useState} from "react";
import MaterialIcons from 'react-native-vector-icons/FontAwesome';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { Platform } from  'react-native'
import { Form, Formik, Field, ErrorMessage} from "formik";
import { Heading as Head} from  './Heading'
///import CountryPicker from 'react-native-country-picker-modal';
import countries from './countries.json'
import * as yup from 'yup';
import {
    Container,
    ScrollView,
    View,
    Select,

    Flex,
    Alert,
    Box,
    Heading,
    VStack,
    FormControl,
    Input,
    Button,
    Center,
    NativeBaseProvider,
    HStack,
    Link,
    Text,
    IconButton, CloseIcon, useColorModeValue, useColorMode, Spacer, Spinner
} from "native-base";
import {axiosInstance, useAppContext} from "./AppContextProvider";
import {isNativeApp} from "./Functions";
import Content from "./Content";


const validate = values => {
    const errors = {};

    if (!values.username) {
        errors.username = 'Required';
    }

    return errors;
};
const LoginScreen = ({navigation})=>{


    return     ( <Content><Login navigation={navigation} /></Content>)


    }

const Login = ({navigation})=>{

    const {auth: {signIn },user, userToken, isLoading, dispatch } = useAppContext()

    const [error,setError]= useState('')

      if(isLoading){
        return <Spinner size={"lg"} >Loading..</Spinner>
    }

    return (

        <Formik initialValues={{
            username: 'dd@dd.com',
            password: 'dddd'
        }}
                validationSchema={validationLoginSchema}
                onSubmit={async (data)=>{
                    data.username = data.username.trim()
                    data.password = data.password.trim()
                    await signIn(data).then((data)=>{
                        console.log('data',data)
                        if(isNativeApp()){
                            console.log('messageposting',JSON.stringify(data))
                           /// window.ReactNativeWebView.postMessage(JSON.stringify({message: 'Login',user:data}))

                        }else {
                        }

                        dispatch({type: 'SIGN_IN', token: data._id, user: data});
                    }).catch((e)=>{
                        console.log('login err',e)
                        setError('Invalid login')
                    }).finally(()=>{

                    })

                }} validate={validate}  >
            {({
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors
              }) =>

                <Form>
                    <Center>
                        <Box safeArea p="2" py="8" w="90%" maxW="290">
                            <Heading size="lg" fontWeight="600" color="coolGray.800" _dark={{
                                color: "warmGray.50"
                            }}>
                                Welcome
                            </Heading>
                            <Heading mt="1" _dark={{
                                color: "warmGray.200"
                            }} color="coolGray.600" fontWeight="medium" size="xs">
                                Sign in to continue!
                            </Heading>
                            {error &&
                            <Alert w="100%" status="error">
                                <VStack space={2} flexShrink={1} w="100%">
                                    <HStack flexShrink={1} space={1} alignItems="center" justifyContent="space-between">
                                        <HStack space={2} flexShrink={1} alignItems="center">
                                            <Alert.Icon />
                                            <Text   _dark={{
                                                color: "coolGray.800"
                                            }}>
                                                Error
                                            </Text>
                                        </HStack>
                                        <IconButton variant="unstyled" _focus={{
                                            borderWidth: 0
                                        }} icon={<CloseIcon size="3" />} _icon={{
                                            color: "coolGray.600"
                                        }} onPress={e=>{setError('')}} />
                                    </HStack>
                                    <Box pl="6" _dark={{
                                        _text: {
                                            color: "coolGray.600"
                                        }
                                    }}>
                                        Invalid login
                                    </Box>
                                </VStack>
                            </Alert>
                            }
                            <VStack space={3} mt="5">
                                <FormControl isInvalid={errors.username}>
                                    <FormControl.Label>Email ID</FormControl.Label>
                                    <Input onBlur={handleBlur('username')} onChangeText={handleChange('username')} value={values.username}/>
                                    <FormControl.ErrorMessage>{errors.username}</FormControl.ErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.password}>
                                    <FormControl.Label>Password</FormControl.Label>
                                    <Input type="password" onChangeText={handleChange('password')} onBlur={handleBlur('password')} value={values.password}/>
                                    <FormControl.ErrorMessage>{errors.password}</FormControl.ErrorMessage>

                                </FormControl>
                                <Button  nativeID="btn-login" mt="2" isLoading={isSubmitting} colorScheme="indigo" onPress={handleSubmit} disabled={isSubmitting}>
                                    Sign in
                                </Button>
                                <HStack mt="6" justifyContent="center">
                                    <Link _text={{
                                        fontWeight: "500",
                                        color: "indigo.500"
                                    }} alignSelf="flex-end" mt="1"
                                          href="#" onPress={() => {
                                        navigation.navigate('Forgot')
                                    }}>
                                        Forget Password?
                                    </Link>
                                    <Spacer/>
                                    <Text  color="coolGray.600" _dark={{
                                        color: "warmGray.200"
                                    }}>
                                        I'm a new user.{" "}
                                    </Text>
                                    <Link _text={{
                                        color: "indigo.500",
                                        fontWeight: "medium",

                                    }} href="#" onPress={() => {
                                        navigation.navigate('Sign')
                                    }}>
                                        Sign Up
                                    </Link>
                                </HStack>
                            </VStack>
                        </Box>
                    </Center>
                </Form>
            }

        </Formik>
    )
};


const ForgotScreen = ({navigation})=>{

    useEffect(()=>{
      console.log('forgotscreen')
    },[])

    return (<Content><PassForm navigation={navigation}/></Content>)


}

const validationLoginSchema = yup.object().shape({
    username: yup.string().email('Invalid email').required('Email is required'),
    password: yup.string().min(4, 'Password must be at least 4 characters').required('Password is required'),
})
const validationForgetSchema = yup.object().shape({
    username: yup.string().email('Invalid email').required('Email is required'),
})

const PassForm = ({navigation}) => {

    const {auth: {forGot } } = useAppContext()
    const [error,setMessage] = useState('')
    return (
        <Formik initialValues={{
            username: '',
        }} onSubmit={async (values,k)=>{

            await forGot({email:values.username}).then(data=>{
                setMessage(data.message)

            }).catch((err)=>{
                console.log('error',err)
                setMessage('Error. Try again')

            })
                .finally(()=>{

            })
        }} validationSchema={validationForgetSchema}>
            {({
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors
              }) =>


                <Form>

                    <Center w="100%">
                        <Box safeArea p="2" w="90%" maxW="290" py="8">
                            <Heading size="lg" color="coolGray.800" _dark={{
                                color: "warmGray.50"
                            }} fontWeight="semibold">
                                Welcome
                            </Heading>
                            <Heading mt="1" color="coolGray.600" _dark={{
                                color: "warmGray.200"
                            }} fontWeight="medium" size="xs">
                                Enter Email for password recovery
                            </Heading>
                            <VStack space={3} mt="5">
                                {error &&
                                <Alert w="100%" status="info">
                                    <VStack space={2} flexShrink={1} w="100%">
                                        <HStack flexShrink={1} space={1} alignItems="center" justifyContent="space-between">
                                            <HStack space={2} flexShrink={1} alignItems="center">
                                                <Alert.Icon />

                                            </HStack>
                                            <IconButton variant="unstyled" _focus={{
                                                borderWidth: 0
                                            }} icon={<CloseIcon size="3" />} _icon={{
                                                color: "coolGray.600"
                                            }} onPress={e=>{setMessage('')}} />
                                        </HStack>
                                        <Box pl="6" _dark={{
                                            _text: {
                                                color: "coolGray.600"
                                            }
                                        }}>
                                            {error}
                                        </Box>
                                    </VStack>
                                </Alert>
                                }
                                <FormControl isInvalid={errors.username}>
                                    <FormControl.Label>Email</FormControl.Label>
                                    <Input onBlur={handleBlur('username')} onChangeText={handleChange('username')} value={values.username}/>
                               <FormControl.ErrorMessage>{errors.username}</FormControl.ErrorMessage>
                                </FormControl>
                                <Button mt="2" isLoading={isSubmitting} colorScheme="indigo" onPress={handleSubmit} disabled={isSubmitting}>
                                    Send Password
                                </Button>
                                <HStack mt="6" justifyContent="center">
                                    <Text  color="coolGray.600" _dark={{
                                        color: "warmGray.200"
                                    }}>
                                    </Text>
                                    <Link _text={{
                                        color: "indigo.500",
                                        fontWeight: "medium",
                                    }} href="#" onPress={() => {
                                        navigation.navigate('Login')
                                    }}>
                                        Sign In
                                    </Link>
                                </HStack> </VStack>
                        </Box>
                    </Center>
                </Form>
            }
        </Formik>
    )

}
const ProfileScreen = ({navigation}) => {
useEffect(()=>{
  console.log('inprofile')
},[])
         return    <Content><ProfileForm navigation={navigation} /> </Content>


}



const SignUpScreen = ({navigation}) => {

                                    return  <Content>
                                        <RegistrationForm navigation={navigation}/></Content>

                                }



// Create a validation schema
const validationRegSchema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    age: yup.number().min(18).required('Age is required'),
    password: yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    repeatPassword: yup.string()
        .required('Repeat Password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'), // Checks if it matches the 'password' field
    countryCode: yup.string().required('Country Code is required'),
    phone: yup
        .string()
        .matches(/^\d+$/, 'Phone Number must contain only digits')
        .min(7, 'Phone Number must be at least 7 digits')
        .required('Phone Number is required'),
});
function ProfileForm({navigation}) {

    const {user, auth: {setProfile}, dispatch} = useAppContext()
    const [formData, setFormData] = useState({
        firstName:'',
        lastName: '',
        email: '',
        password: '',
        countryCode: '', // Store the selected country code here
        phone: '',
    });
    useEffect(()=>{
        console.log('inprofile2')
        setFormData(user)
    },[user])

    const [error,setError]= useState('')

    const [countryCodePickerVisible, setCountryCodePickerVisible] = useState(false);

    const handleCountryCodeSelect = (country) => {
        setCountryCodePickerVisible(false);
        setFormData({
            ...formData,
            countryCode: country.cca2,
        });
    };

    if(!user){
        return <Spinner size={'lg'} />
    }


    return (
        <Formik
            key={user._id}
            //validateOnBlur={false}
            //validateOnChange={false}
            //validateOnMount={false}
            enableReinitialize={true}
            initialValues={formData}
            validationSchema={validationRegSchema}
            onSubmit={async (values, { resetForm }) => {
                // Handle form submission logic here
                  setProfile(values).then(({data})=>{
                        setError('Profile updated')


                 }).catch((err)=>{
                     console.log('error',err)
                     setError(err)
                 }).finally(()=>{

                });
            }}
        >
            {({
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  values,
                  isValid
              }) => {
                return (

                    <Form>
                        <Center>
                            <Box safeArea p="2" py="8" w="90%" maxW="290">
                               <Heading mt="1" _dark={{
                                    color: "warmGray.200"
                                }} color="coolGray.600" fontWeight="medium" size="xs">
                                   Edit Profile
                                </Heading>

                                {error &&
                                <Alert w="100%" status="info">
                                    <VStack space={2} flexShrink={1} w="100%">
                                        <HStack flexShrink={1} space={1} alignItems="center"
                                                justifyContent="space-between">
                                            <HStack space={2} flexShrink={1} alignItems="center">
                                                <Alert.Icon/>
                                                <Text  fontWeight="medium" _dark={{
                                                    color: "coolGray.800"
                                                }}>
                                                    Info
                                                </Text>
                                            </HStack>
                                            <IconButton variant="unstyled" _focus={{
                                                borderWidth: 0
                                            }} icon={<CloseIcon size="3"/>} _icon={{
                                                color: "coolGray.600"
                                            }} onPress={e => {
                                                setError('')
                                            }}/>
                                        </HStack>
                                        <Box pl="6" _dark={{
                                            _text: {
                                                color: "coolGray.600"
                                            }
                                        }}>
                                            {error}
                                        </Box>
                                    </VStack>
                                </Alert>
                                }
                                <VStack space={3} mt="5">
                                    <FormControl isInvalid={errors.firstName}>
                                        <FormControl.Label>First Name</FormControl.Label>
                                        <Input
                                            type="text"
                                            placeholder="First Name"
                                            onChangeText={handleChange('firstName')}
                                            value={values.firstName}
                                        />
                                        <FormControl.ErrorMessage>
                                            {errors.firstName}
                                        </FormControl.ErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.lastName}>
                                        <FormControl.Label>Last Name</FormControl.Label>
                                        <Input
                                            type="text"
                                            placeholder="Last Name"
                                            onChangeText={handleChange('lastName')}
                                            value={values.lastName}
                                        />
                                        <FormControl.ErrorMessage>
                                            {errors.lastName}
                                        </FormControl.ErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.age}>
                                        <FormControl.Label>Age</FormControl.Label>
                                        <Input
                                            type="text"
                                            placeholder="Age"
                                            onChangeText={handleChange('age')}
                                            value={values.age}
                                        />
                                        <FormControl.ErrorMessage>
                                            {errors.age}
                                        </FormControl.ErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.password}>
                                        <FormControl.Label>Password</FormControl.Label>
                                        <Input
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            value={values.password}
                                            onChangeText={handleChange('password')}
                                            onBlur={handleBlur('password')}
                                        />
                                        <FormControl.ErrorMessage>{errors.password}</FormControl.ErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={!!errors.repeatPassword}>
                                        <FormControl.Label>Repeat Password</FormControl.Label>
                                        <Input
                                            type="password"
                                            name="repeatPassword"
                                            placeholder="Repeat Password"
                                            value={values.repeatPassword}
                                            onChangeText={handleChange('repeatPassword')}
                                            onBlur={handleBlur('repeatPassword')}
                                        />
                                        <FormControl.ErrorMessage>{errors.repeatPassword}</FormControl.ErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.email}>
                                        <FormControl.Label>Email</FormControl.Label>
                                        <Input
                                            type="text"
                                            placeholder="Email"
                                            onChangeText={handleChange('email')}
                                            value={values.email}
                                        />
                                        <FormControl.ErrorMessage>
                                            {errors.email}
                                        </FormControl.ErrorMessage>
                                    </FormControl>

                                    <FormControl>
                                        <FormControl.Label>Select Country Code</FormControl.Label>
                                        <Select
                                            selectedValue={values.countryCode}
                                            onValueChange={handleChange('countryCode')}
                                        >
                                            <Select.Item label="Select Country Code" value=""/>
                                            {Object.keys(countries).map((k) => (
                                                <Select.Item key={k} label={countries[k]} value={k}/>
                                            ))}
                                        </Select>
                                        <FormControl.ErrorMessage>
                                            {errors.countryCode}
                                        </FormControl.ErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.phone}>
                                        <FormControl.Label>Phone Number</FormControl.Label>
                                        <Input
                                            type="text"
                                            placeholder="Phone Number"
                                            onChangeText={handleChange('phone')}
                                            value={values.phone}
                                        />
                                        <FormControl.ErrorMessage>
                                            {errors.phone}
                                        </FormControl.ErrorMessage>
                                    </FormControl>

                                    <Button colorScheme={'indigo'} mt={2} onPress={(e) => {
                                        handleSubmit(e)
                                    }}
                                    disabled={isSubmitting} isLoading={isSubmitting}
                                    >
                                       Save Profile
                                    </Button>


                                </VStack>
                            </Box>
                        </Center>
                    </Form>
                )
            }

            }</Formik>
    )
}

function RegistrationForm({navigation}) {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        countryCode: '', // Store the selected country code here
        phone: '',
    });
    const {auth: {signUp }, dispatch } = useAppContext()

    const [error,setError]= useState('')

    const [countryCodePickerVisible, setCountryCodePickerVisible] = useState(false);

    const handleCountryCodeSelect = (country) => {
        setCountryCodePickerVisible(false);
        setFormData({
            ...formData,
            countryCode: country.cca2,
        });
    };

   return (
               <Formik
                    //validateOnBlur={false}
                    //validateOnChange={false}
                    //validateOnMount={false}
                    initialValues={formData}
                    validationSchema={validationRegSchema}
                    onSubmit={ async  (values, { resetForm }) => {
                        // Handle form submission logic here
                       // await  signUp(values)
                            signUp(values).then(()=>{

                                setError('Success')
                                setTimeout(()=>{
                                    navigation.navigate('Login')
                                },1000)
                            }).catch((err)=>{
                                console.log('error',err)
                               setError(err.message)
                            })

                        //     resetForm(); // Clear the form after submission
                    }}
                >
                    {({
                         isSubmitting,
                        handleChange,
                        handleBlur,
                          handleSubmit,
                         errors,
                          touched,
                        values,
                          isValid
                      }) => {
                        return (

                            <Form>
                                <Center>
                                    <Box safeArea p="2" py="8" w="90%" maxW="290">
                                        <Heading size="lg" fontWeight="600" color="coolGray.800" _dark={{
                                            color: "warmGray.50"
                                        }}>
                                            Welcome
                                        </Heading>
                                        <Heading mt="1" _dark={{
                                            color: "warmGray.200"
                                        }} color="coolGray.600" fontWeight="medium" size="xs">
                                            Sign in to continue!
                                        </Heading>

                                        {error &&
                                        <Alert w="100%" status="error">
                                            <VStack space={2} flexShrink={1} w="100%">
                                                <HStack flexShrink={1} space={1} alignItems="center"
                                                        justifyContent="space-between">
                                                    <HStack space={2} flexShrink={1} alignItems="center">
                                                        <Alert.Icon/>
                                                        <Text  fontWeight="medium" _dark={{
                                                            color: "coolGray.800"
                                                        }}>
                                                            Error
                                                        </Text>
                                                    </HStack>
                                                    <IconButton variant="unstyled" _focus={{
                                                        borderWidth: 0
                                                    }} icon={<CloseIcon size="3"/>} _icon={{
                                                        color: "coolGray.600"
                                                    }} onPress={e => {
                                                        setError('')
                                                    }}/>
                                                </HStack>
                                                <Box pl="6" _dark={{
                                                    _text: {
                                                        color: "coolGray.600"
                                                    }
                                                }}>
                                                    {error}
                                                </Box>
                                            </VStack>
                                        </Alert>
                                        }
                                        <VStack space={3} mt="5">
                                            <FormControl isInvalid={errors.firstName}>
                                                <FormControl.Label>First Name</FormControl.Label>
                                                <Input
                                                    type="text"
                                                    placeholder="First Name"
                                                    onChangeText={handleChange('firstName')}
                                                    value={values.firstName}
                                                />
                                                <FormControl.ErrorMessage>
                                                    {errors.firstName}
                                                </FormControl.ErrorMessage>
                                            </FormControl>
                                           <FormControl isInvalid={errors.lastName}>
                                                <FormControl.Label>Last Name</FormControl.Label>
                                                <Input
                                                    type="text"
                                                    placeholder="Last Name"
                                                    onChangeText={handleChange('lastName')}
                                                    value={values.lastName}
                                                />
                                                <FormControl.ErrorMessage>
                                                    {errors.lastName}
                                                </FormControl.ErrorMessage>
                                            </FormControl>
                                            <FormControl isInvalid={errors.age}>
                                                <FormControl.Label>Age</FormControl.Label>
                                                <Input
                                                    type="text"
                                                    placeholder="Age"
                                                    onChangeText={handleChange('age')}
                                                    value={values.age}
                                                />
                                                <FormControl.ErrorMessage>
                                                    {errors.age}
                                                </FormControl.ErrorMessage>
                                            </FormControl>
                                            <FormControl isInvalid={!!errors.password}>
                                                <FormControl.Label>Password</FormControl.Label>
                                                <Input
                                                    type="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    value={values.password}
                                                    onChangeText={handleChange('password')}
                                                    onBlur={handleBlur('password')}
                                                />
                                                <FormControl.ErrorMessage>{errors.password}</FormControl.ErrorMessage>
                                            </FormControl>

                                            <FormControl isInvalid={!!errors.repeatPassword}>
                                                <FormControl.Label>Repeat Password</FormControl.Label>
                                                <Input
                                                    type="password"
                                                    name="repeatPassword"
                                                    placeholder="Repeat Password"
                                                    value={values.repeatPassword}
                                                    onChangeText={handleChange('repeatPassword')}
                                                    onBlur={handleBlur('repeatPassword')}
                                                />
                                                <FormControl.ErrorMessage>{errors.repeatPassword}</FormControl.ErrorMessage>
                                            </FormControl>
                                            <FormControl isInvalid={errors.email}>
                                                <FormControl.Label>Email</FormControl.Label>
                                                <Input
                                                    type="text"
                                                    placeholder="Email"
                                                    onChangeText={handleChange('email')}
                                                    value={values.email}
                                                />
                                                <FormControl.ErrorMessage>
                                                    {errors.email}
                                                </FormControl.ErrorMessage>
                                            </FormControl>

                                            <FormControl>
                                                <FormControl.Label>Select Country Code</FormControl.Label>
                                                <Select
                                                    selectedValue={values.countryCode}
                                                    onValueChange={handleChange('countryCode')}
                                                >
                                                    <Select.Item label="Select Country Code" value=""/>
                                                    {Object.keys(countries).map((k) => (
                                                        <Select.Item key={k} label={countries[k]} value={k}/>
                                                    ))}
                                                </Select>
                                                <FormControl.ErrorMessage>
                                                    {errors.countryCode}
                                                </FormControl.ErrorMessage>
                                            </FormControl>

                                            <FormControl isInvalid={errors.phone}>
                                                <FormControl.Label>Phone Number</FormControl.Label>
                                                <Input
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    onChangeText={handleChange('phone')}
                                                    value={values.phone}
                                                />
                                                <FormControl.ErrorMessage>
                                                    {errors.phone}
                                                </FormControl.ErrorMessage>
                                            </FormControl>

                                            <Button colorScheme={'indigo'} mt={2} onPress={(e) => {
                                                handleSubmit(e)
                                            }}

                                                    disabled={isSubmitting} isLoading={isSubmitting}
                                            >
                                                Register
                                            </Button>
                                            <HStack mt="6" justifyContent="center">
                                                <Text pl={1}  color="coolGray.600" _dark={{
                                                    color: "warmGray.200"
                                                }}>
                                                    Already have Account?
                                                </Text>
                                                <Link _text={{
                                                    color: "indigo.500",
                                                    fontWeight: "medium",

                                                }} href="#" onPress={() => {
                                                    navigation.navigate('Login')
                                                }}>
                                                    Sign In
                                                </Link>
                                            </HStack>


                                        </VStack>
                                    </Box>
                                </Center>
                            </Form>
                        )
                    }

                     }</Formik>
     )
}



const styles = {
    labelContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    label: {
        flex: 1,
        marginRight: 10,
        fontWeight: 'bold',
    },
};

const Logout = ({navigation})=>{

    const {auth: {signOut } } = useAppContext()
    useEffect(()=>{

      if(Platform.OS==='android'){
      }else{
          signOut().then(()=>{

          ///  window.postMessage({message: 'Logout'})

          })
      }
      return () => {
        if(Platform.OS==='android') {
      ///      window.removeEventListener('message', receivedMessage)

        }
      }

    },[])


    if(Platform.OS==='android'){

        return <Box>Webviewpage</Box>
    }
    return <>
            Logout</>
}

export {LoginScreen,SignUpScreen,ProfileScreen,ForgotScreen, Logout}
