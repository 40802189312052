import {Flex, Spinner, View} from "native-base";
import {Heading as Head} from "./Heading";
import React, {useEffect, useMemo, useState} from "react";
import {useRoute} from "@react-navigation/native";
import {axiosInstance, useAppContext} from "./AppContextProvider";
import HTML from 'react-native-render-html'

import RenderHtml from 'react-native-render-html'
const PageScreen=({navigation}) => {
const [html,setHtml]=useState('')
const {links}=useAppContext()
    const route = useRoute();
    const receivedData = 
    
    useMemo(()=>{

    return route.params?? {}; // Default to an empty object
    
},[route.params])

    useEffect(()=>{
        if(receivedData.id) {
            //setHtml(links.)
            const found = links.find(obj => obj.id === receivedData.id);
            if(found){
                setHtml(found.body)
            }
        }

    },[receivedData,links])

return  (

    <Flex
        p={1}
        safeArea
        flexWrap={'no-wrap'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={"stretch"}
        justifyItems={'center'}
        w="100%"

        _light={{
            bg: 'coolGray.50',
        }}
        _dark={{
            bg: 'blueGray.900',
        }}
    ><Head navigation={navigation}/>
        {html &&
        <View>
        <HTML source={{html:html}}/>
       </View>}
        {!html && <Spinner size={"lg"}/> }

</Flex>)


}

export  {PageScreen}
