import { extendTheme } from 'native-base';

export const BaseTheme = extendTheme({

    fontConfig: {
        Roboto: {

            100: {
                normal: "Roboto_100Thin",
                italic: "Roboto_100Thin_Italic",
            },
            300: {
                normal: "Roboto_300Light",
                italic: "Roboto_300Light_Italic",
            },
            400: {
                normal: "Roboto_400Regular",
                italic: "Roboto_400Regular_Italic",
            },
            500: {
                normal: "Roboto_500Medium",
                italic: "Roboto_500Medium_Italic",
            },
            700: {
                normal: "Roboto_700Bold",
                italic: "Roboto_700Bold_Italic",
            },
            900: {
                normal: "Roboto_900Black",
                italic: "Roboto_900Black_Italic",
            }



        },
    },

    // Make sure values below matches any of the keys in `fontConfig`
    fonts: {
        heading: "Roboto",
        body: "Roboto",
        mono: "Roboto",
    },

    fontSizes: {
        lg:40,
        md: 32,
        sm : 15,
        xs:12
    },

    components: {
       Text: {
            defaultProps: {
                fontSize: 'sm',
                fontFamily: 'Roboto_900Black_Italic',
                color: 'black.200',
                backgroundColor: 'white'
            },
           sm: {
                fontSize:  40,
                fontFamily: 'Roboto_900Black_Italic',
           },
           xs: {
               fontSize:  40,
               fontFamily: 'Roboto_900Black_Italic',
           }
        },
    },
    colors: {
        slateGray: {
            50: '#f3f2f2',
            100: '#d8d8d8',
            200: '#bebebe',
            300: '#a3a3a3',
            400: '#898989',
            500: '#6f6f6f',
            600: '#565656',
            700: '#3e3e3e',
            800: '#252525',
            900: '#0d0c0d',
        },
    },
    Pressable: {
        cursor: 'pointer',
    },

    config: {
        // Changing initialColorMode to 'dark'
        initialColorMode: 'dark',
    },
});
