import React, {useRef} from "react";
import {
    NativeBaseProvider,
    ColorMode,
    Center,
    Box,
    Text,
    Heading as Head,
    Button,
    Divider,
    HStack,
    Card,
    View,
    useColorModeValue
} from "native-base";
import type, { StorageManager, extendTheme, useColorMode, Icon, Flex } from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";
import MaterialIcons from 'react-native-vector-icons/FontAwesome';
import Ionicons from 'react-native-vector-icons/Ionicons';
import VideoPlayer from "./VideoPlayer";
import {Heading} from "./Heading";
import {Composition} from "./Composition";
import {Footer} from "./Components";
const videoUrl  = 'https://video.bunnycdn.com/embed/435/a980133a-1f98-4efe-9b9e-f31e9be25744?autoplay=true'
//const videoUrl = 'https://video.bunnycdn.com/embed/435/a980133a-1f98-4efe-9b9e-f31e9be25744?autoplay=true'
const HomeScreen = ({navigation})=>{
const colorMode = useColorMode()
    return (

    <Flex
      p={1}
      safeArea
      flexWrap={'no-wrap'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignContent={'center'}
      alignItems={"stretch"}
      justifyItems={'center'}
       w="100%"

        _light={{
            bg: 'coolGray.50',
        }}
        _dark={{
            bg: 'blueGray.900',
        }}
         bg={useColorModeValue('blue.900', 'blueGray.900')}

    >


            <Heading navigation={navigation}/>

       <Divider opacity={colorMode == 'dark' ? '0.4' : '1'} my={0} py={0} h={2} flexGrow={0} flexShrink={0}/>

                <Composition navigation={navigation}/>


        </Flex>


    )
}

export default HomeScreen
