/* eslint-disable import/no-anonymous-default-export */
import React, {useEffect} from "react";
import {NativeBaseProvider, ColorMode, Center, VStack, useToken, useColorModeValue, Spinner} from "native-base";
import type, {StorageManager, extendTheme, useColorMode, Icon, View, Box} from "native-base";
import MaterialIcons from 'react-native-vector-icons/FontAwesome';
import Ionicons from 'react-native-vector-icons/Ionicons';
//import { MaterialCommunityIcons, AntDesign, Entypo } from "@expo/vector-icons";
import {NavigationContainer, NavigationContainerRef} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/native-stack';
import {enableFreeze} from 'react-native-screens';
import {Linking, Platform} from 'react-native';
import axios from "axios";

import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {
    SafeAreaProvider,
    useSafeAreaInsets,
} from 'react-native-safe-area-context';
import {useFocusEffect, useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {AppProvider, useAppContext} from "./AppContextProvider";
import HomeScreen from "./Home";
import {LoginScreen, SignUpScreen} from "./AuthForms";
import {PanicScreen, TreatmentScreen} from "./Treatment";
import {Footer} from "./Components";
import {BaseTheme as theme} from "./theme";
import {Root} from "./Root";
import {addDeepLinkListener, handleDeepLink} from "./DeepLink";
import {MyStorage} from "./Functions";
//import useCachedResources from "./Hook";

const newColorTheme = {
    brand: {
        900: "#8287af",
        800: "#7c83db",
        700: "#b3bef6",
    },
};

//const theme = extendTheme({ colors: newColorTheme });
export default ({children}) => {
   //console.log('apptheme',theme)
    const colorModeManager = {
        get: async () => {
            try {
                let val = await MyStorage.getItem("@my-app-color-mode");
                return val === "dark" ? "dark" : "light";
            } catch (e) {
                console.log(e);
                return "light";
            }
        },
        set: async (value) => {
            try {
                await MyStorage.setItem("@my-app-color-mode", value);
            } catch (e) {
                console.log(e);
            }
        },
    };

///  const { colorMode } = useColorMode();
    // const Stack = createNativeStackNavigator();
    //const insets = useSafeAreaInsets();


    /*
    useEffect(()=>{

      axios.interceptors.request.use(function (config) {
        const siteUrl=  'https://testwp.com/' ///window.siteUrl// 'https://xero.milcase.co.uk';
        config.baseURL = siteUrl; // base url for your api.
        if(userToken) {
          config.headers.authorization = `Bearer ${userToken}`;
        }
        return config;
      }, function (error) {
        return Promise.reject(error);
      });

    },[userToken])
  */
    /*
    const [lightBg, darkBg] = useToken(
        'colors',
        ['coolGray.50', 'blueGray.900'],
        'blueGray.900',
    );

    const bgColor = useColorModeValue(lightBg, darkBg);
  */
//const isLoadingComplete = useCachedResources()
    const navigationRef = React.createRef();

    /*
    useEffect(() => {
        const evt = addDeepLinkListener(navigationRef);
        Linking.getInitialURL().then(url => {
            if (url) {
                console.log('Event url param2', url)
                handleDeepLink(url, navigationRef);
            }
        });
        return () => {
            evt.remove()
        }

    }, []);
*/
    /*
      const [lightBg, darkBg] = useToken(
          'colors',
          ['coolGray.50', 'blueGray.900'],
          'blueGray.900',
      );
      const bgColor = useColorModeValue(lightBg, darkBg);
    */

    return (
        <NavigationContainer
            ref={navigationRef}
/*
            onStateChange={({routes}) => {
                const currentRouteName = routes[routes.length - 1]?.name;
                if (!currentRouteName) return;

                // Check if the route name is not in the list of known routes
                const isUnknownRoute = !['Page', 'Sign', 'Login', 'Forgot'].includes(currentRouteName);

                if (isUnknownRoute) {
                    navigationRef.current?.navigate('Login'); // Navigate to the Not Found screen
                }
            }}*/
        >


            <NativeBaseProvider theme={theme} colorModeManager={colorModeManager}>
                <Root/>
            </NativeBaseProvider>
        </NavigationContainer>
    );
};
