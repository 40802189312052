import {useSafeAreaInsets} from "react-native-safe-area-context";
import {Container, Box,View  } from "native-base";
import {Footer} from "./Components";
import React, {useEffect, useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {Heading} from "./Heading";
import HTML from "react-native-render-html";
import {isNativeApp} from "./Functions";
import {axiosInstance, useAppContext} from "./AppContextProvider";
import { StyleSheet, ScrollView  } from "react-native";


const Content = ({children})=>{
    const insets = useSafeAreaInsets();
    const navigation = useNavigation()
    const [hasu,setHasu]=useState()
    const [text,setText]=useState('Loading..')
    const {userToken} = useAppContext()

    const [isMobile, setIsMobile] = React.useState(false);
    useEffect(()=>{
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };
        if(userToken){
            setHasu(true)
        }
        if(isNativeApp()){
            setIsMobile(true)
        }

        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        axiosInstance.get('/page?id=footer').then(({data})=>{
              setText(data.text)
            //setHtml(data)

        }).catch(()=>{
            //  setText('Error')
        }).finally(()=>{

      //      setIsLoading(false)
        })

        return () => {
            // Remove event listener when component unmounts
            window.removeEventListener('resize', handleResize);
        };
    },[userToken])
    // bg={colorMode === "light" ? "coolGray.50" : "coolGray.900"}
    return (
        <View
            minHeight="100vh"
            justifyContent="center"
            px={0}
            padding={0}
            margin={0}
            style={{
                flex: 1,
                justifyContent: 'flex-start',
                alignItems: 'stretch',

             //   paddingBottom: insets.bottom,
               // paddingLeft: insets.left,
                //paddingRight: insets.right,
                }}
            >

           <Heading navigation={navigation}/>
            {children}

            {hasu && <Footer/>}
            { (!hasu&&!isMobile) && <><View flexGrow={1} bg={'#BBF7D0'}></View> <View safeAreaBottom bottom={0} right={0} justifyContent={'center'} color={'white'} alignItems={'center'}   bg={'blue.900'} width={'100%'}

            >

                <HTML source={{html:text}}/>

            </View></>  }
            </View>
    )
}
const styles1 = StyleSheet.create({
    container: {
        flex: 1,
    },
    contentContainer: {
        flexGrow: 1,
    },
    header: {
        backgroundColor: 'blue',
        height: 60,
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerText: {
        color: 'white',
        fontSize: 20,
    },
    content: {
        padding: 20,
    },
});

const styles = StyleSheet.create({
    container: {
        alignSelf: 'stretch'
    },
    scrollView: {
        backgroundColor: '#eeeeee',
        maxHeight: 250
    },
    disabled: {
        opacity: 0.5
    },
    item: {
        margin: 5,
        padding: 5,
        backgroundColor: '#cccccc',
        borderRadius: 3,
        minWidth: 96
    },
    text: {
        fontSize: 16,
        fontWeight: 'bold',
        margin: 5
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: '1rem'
    },
    divider: {
        width: '1rem'
    }
});

export default Content
