import React, {useEffect, useState} from 'react';

import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {NavigationContainer} from '@react-navigation/native';
import {Box, Spinner, useColorModeValue, useToken} from 'native-base';
import HomeScreen from "./Home";
import {TreatmentScreen} from "./Treatment";
import {ForgotScreen, LoginScreen, Logout, ProfileScreen, SignUpScreen} from "./AuthForms";
import {axiosInstance, useAppContext} from "./AppContextProvider";
import {Footer, ImageModalSheet, VideoModalSheet} from "./Components";
import {PageScreen} from "./Page";
import {addDeepLinkListener} from "./DeepLink";
import {isNativeApp, parseQueryString} from "./Functions";

const Stack = createNativeStackNavigator()


export const Root = () => {
    const [initialState, setInitialState] = React.useState('');
    const [route, setRoute] = useState({name: null, params: null})
    const [hasu, setHasu] = useState(false)
    const {isLoading, userToken, links, dispatch, user} = useAppContext();

    useEffect(() => {
        if(isNativeApp()){
            setInitialState('native')
        }
        axiosInstance.get('/page?id=links').then(({data}) => {
            dispatch({type: 'LINKS', data: {links: data}})

        }).catch((err) => {
            console.log('err', err)
        }).finally(() => {
        })

    }, [])
    useEffect(()=>{

    },[hasu])
    useEffect(() => {
        if (userToken  && user!==null) {
            setHasu(true)
        }else{
            setHasu(false)
        }
    }, [user, userToken])


    /*
        if(links.length === 0){
            return <Spinner size={'lg'}  />
        }
    */
    return (


        <>
            {hasu ?
                (


                    <Stack.Navigator id={'auth'}>
                        <Stack.Screen name={'Home'} component={HomeScreen} options={{headerShown: false}}/>
                        <Stack.Screen name={'Profile'} component={ProfileScreen} options={{headerShown: false}}/>
                        <Stack.Screen name={'Page'} component={PageScreen} options={{headerShown: false}}/>

                        <Stack.Screen name={'Logout'} component={Logout} options={{headerShown: false}}/>
                        <Stack.Group screenOptions={{presentation: "modal"}}>
                            <Stack.Screen name={'Treatment'} component={TreatmentScreen}/>
                        </Stack.Group>

                    </Stack.Navigator>

                )
                :
                (
                    <Stack.Navigator id={'unauth'}>
                        <Stack.Screen name={'Login'} component={LoginScreen}/>
                        <Stack.Screen name={'Page'} component={PageScreen} options={{headerShown: false}}/>
                        <Stack.Screen name={'Forgot'} component={ForgotScreen}/>
                        <Stack.Screen name={'Sign'} component={SignUpScreen}/>
                    </Stack.Navigator>
                )


            }

        </>


    );
};
