import {Platform} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
const isNativeApp=()=> {
    return /pigig/.test(navigator.userAgent);
}
const handleError = (error)=>{

    let msg;
    if (error.response) {
        msg =  error.response.data.message
    } else if (error.request) {
        msg = 'Error '
        console.error('No response received');
    } else {
        // Something happened in setting up the request that triggered an error
        msg = 'Server Error'
        console.error('Request error:', error.message);
    }

    throw msg

}
function parseQueryString(url) {
    if (!url) {
        url = window.location.href
    }
    const queryString = url.replace(/.*\?/, '');

    if (queryString === url || !queryString) {
        return null;
    }

    const urlParams = new URLSearchParams(queryString);
    const result = {};

    urlParams.forEach((val, key) => {
        if (result.hasOwnProperty(key)) {
            result[key] = [result[key], val];
        } else {
            result[key] = val;
        }
    });

    return result;
}

const addEvent = (evt, x) => {

    window.addEventListener(evt, x)
}

const resizeEvent = (x) => {
    x()
}

const msgEvent = (x) => {
    x().then(() => {
        window.postMessage({message: 'signout'});
    })

}

const removeEvent = (evt, x) => {
    window.removeEventListener(evt, x)
}
class StorageClass{

    constructor(){
        this.native = isNativeApp()
    }
    async  getItem(key){

            if(this.native){
                console.log('getting '+key,'window',window.Android)

                // eslint-disable-next-line no-undef
               if(Android===null){
                   console.log('localstorage is null '+key)
                   throw Error('null e')
               }
                // eslint-disable-next-line no-undef
                return Promise.resolve(Android.getItem(key))

            }
            if(AsyncStorage === null){
                console.log('async storage is null '+key)
                throw Error('async null e')

            }
            return AsyncStorage.getItem(key)
    }
    async  setItem(key,val){
        if(this.native){
            // eslint-disable-next-line no-undef
            if(Android===null){
                console.log('localstorage is null '+key)
                throw Error('null e')
            }

            // eslint-disable-next-line no-undef
            return Promise.resolve(Android.setItem(key,val))

        }

        return AsyncStorage.setItem(key,val)

    }
    async removeItem(key){

        if(this.native){
            // eslint-disable-next-line no-undef
            if(Android===null){
                console.log('localstorage is null '+key)
                throw Error('null e')
            }

            // eslint-disable-next-line no-undef
            return Promise.resolve(Android.removeItem(key))

        }

        return AsyncStorage.removeItem(key)

    }


}
const MyStorage = new StorageClass()

export { handleError, parseQueryString, addEvent, removeEvent, msgEvent, resizeEvent , isNativeApp,MyStorage};
