import React from 'react';
//https://github.com/GeekyAnts/NativeBase/issues/3217
//https://github.com/oblador/react-native-vector-icons#web-with-webpack
import {

    Box,
    IconButton,
    VStack,
    Drawer,
    HStack,
    ArrowBackIcon,
    Text,
    View,
    Image,
    Button,
    Stack, Menu, Pressable, HamburgerIcon, Spacer, Spinner,
} from 'native-base';

import {useAppContext} from "./AppContextProvider";
//import  Ionicons from "react-native-vector-icons/Ionicons";
import Icon from 'react-native-vector-icons/MaterialIcons';
import {Link} from "@react-navigation/native";
import {Config} from "./Config";


export function Heading({
                            title,
                            navigation,
                        }) {
    const {user, links, auth:{signOut}} = useAppContext()
    const [isMobile, setIsMobile] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        const handleResize = () => {

            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed

        }

        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        return () => {
            // Remove event listener when component unmounts
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    if (links.length === 0) {
        return <Spinner size={'lg'}/>
    }

    return (
           <HStack
                justifyContent={'space-between'}
                alignItems={'center'}
                bg={'blue.200'}
                padding={0}
                margin={0}


               // h={'100%'}
                mb={{base: 0, md: 0}}
                width={{base: '100%', lg: '768px'}}
                alignSelf={{base: 'stretch', md: 'center'}}
                //ml={{base: 0, md: '-16px'}}
            >

                <HStack space={2}
                >
                    <Image
                        flexGrow={0}
                        flexShrink={0}
                        flexBasis={{md: 100, base: 50}}
                        resizeMode={'contain'}
                        resizeMethod={'scale'}

                        source={{
                            uri: Config.HOST+'logo.png'
                        }}/>
                    <View style={{justifyContent: 'center'}}>

                        <Button variant={"ghost"} onPress={e => {
                            console.log('homeb',user)
                           if(user){
                               navigation.navigate('Home')


                           }else{
                               navigation.navigate('Login')
                           }
                        }}>
                            <Text fontSize={"xl"} fontStyle={'italic'} fontFamily={"heading"}>
                                CureMe
                            </Text>
                        </Button>
                    </View>
                </HStack>

                <HStack s flexShrink={0} flexGrow={1} justifyContent={'flex-end'} pr={5} alignItems={'center'}>


                    {!user &&

                    <Button variant={"link"} onPress={e => {
                        navigation.navigate('Login')
                    }}>
                        Login
                    </Button>


                    }
                    {!user && !isMobile &&

                    <>

                        <Button variant={"link"} onPress={e => {

                            navigation.navigate('Page', {id: links[0].id})
                        }}>
                            {links[0]['title']}
                        </Button>
                        <Button variant={"link"} onPress={e => {

                            navigation.navigate('Page', {id: links[1].id})
                        }}>
                            {links[1]['title']}
                        </Button>

                    </>

                    }
                    {user && !isMobile &&


                    <>
                        <Button variant={"link"} onPress={e => {
                            navigation.navigate('Home')
                        }}>
                           Treatment
                        </Button>

                        <Button variant={"link"} onPress={e => {

                            navigation.navigate('Page', {id: links[0].id})
                        }}>
                            {links[0]['title']}
                        </Button>
                        <Button variant={"link"} onPress={e => {

                            navigation.navigate('Page', {id: links[1].id})
                        }}>
                            {links[1]['title']}
                        </Button>


                        <Button variant={"link"} onPress={e => {
                            signOut().then(()=>{
                                //window.location.reload()
                                // navigation.navigate('Login')
                            })
                            //navigation.navigate('Logout')
                        }}>
                            Logout
                        </Button>
                        <Button variant={"link"} onPress={e => {

                            navigation.navigate('Profile')
                        }}>
                            {user.email}
                        </Button>
                    </>
                    }


                    {isMobile ?


                        <Menu w="190" trigger={triggerProps => {
                            return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                                <HamburgerIcon/>
                            </Pressable>
                        }}>

                            {user && <Menu.Item key={1000} onPress={() => {
                                navigation.navigate('Profile')
                            }
                            }>{user.email}</Menu.Item>


                            }
                            {user && <Menu.Item key={103} onPress={() => {
                                navigation.navigate('Home')
                            }
                            }>Treatment</Menu.Item>


                            }

                            {links.map((link, i) => {

                                return <Menu.Item key={i} onPress={() => {
                                    navigation.navigate('Page', {id: link.id})
                                }
                                }>{link['title']}</Menu.Item>
                            })}
                            {user && <Menu.Item key={1000} onPress={() => {
                                signOut()
                                // navigation.navigate('Logout')
                            }
                            }>Logout</Menu.Item>
                            }
                            <Menu.Item key={333333} onPress={()=>{
                                setIsOpen(false)
                            }}>Close or [ESC]</Menu.Item>

                        </Menu>


                        :
                        (
                            <HStack justifyContent={'flex-end'}>


                                <Menu w="190" justifyContent={'center'}


                                      trigger={triggerProps => {
                                    console.log('triggerpro',triggerProps)
                                    return (
                                        <Button alignSelf="center" variant="solid" {...triggerProps}>
                                            More..
                                        </Button>


                                    )
                                }}>
                                    {links.slice(2).map((link, i) => {

                                        return <Menu.Item key={i} onPress={() => {
                                            navigation.navigate('Page', {id: link.id})
                                        }
                                        }>{link['title']}</Menu.Item>
                                    })}

                                <Menu.Item key={333333} onPress={()=>{
                                   setIsOpen(false)
                                }}>Close or [ESC]</Menu.Item>
                                </Menu>

                            </HStack>
                        )

                    }


                </HStack>

            </HStack>

    );
}
