import React, {useEffect, useState} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import HTML from 'react-native-render-html';
import {Config} from './Config'
import {Formik} from "formik";
import {
    Box,
    Heading,
    VStack,
    FormControl,
    Input,
    Button,
    Center,
    NativeBaseProvider,
    HStack,
    Link,
    Text,
    SimpleGrid,
    IconButton,
    Spinner,
    Icon,
    Modal,
    View,
    Image, AspectRatio, Flex
} from "native-base";
import {axiosInstance, useAppContext } from "./AppContextProvider";
import MaterialIcons from 'react-native-vector-icons/FontAwesome';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import  { Component } from 'react';
import { Dimensions} from 'react-native';
import PropTypes from 'prop-types';
import VideoPlayer from "./VideoPlayer";
import {useWindowDimensions} from "react-native-web";
import {isNativeApp} from "./Functions";
import {useRoute} from "@react-navigation/native";



//import { Image } from 'react-native-animatable';
const WIDTH = Dimensions.get('window').width;

const ImageViewer = ({navigation,imageUrl})=>{



}


class GalleryImage extends Component {
    render() {
        const { uri, index, onPress } = this.props;
       // console.log('draw',uri)
        return (
            <Button
                onPress={() => onPress(index)}
                style={{
                    backgroundColor: 'transparent',
                    borderRadius: 0,
                    height: 80,
                    width: WIDTH / 3,
                }}
            >
                <Image
                    animation={'bounceIn'}
                    delay={100 * index}
                    duration={500}
                    source={ uri }
                    style={{
                        height: 80,
                        left: 0,
                        resizeMode: 'cover',
                        top: 0,
                        width: WIDTH / 3,
                    }}
                />
            </Button>
        );
    }
}
GalleryImage.propTypes = {
    uri: PropTypes.string,
    index: PropTypes.number,
    onPress: PropTypes.func,
};

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.openLightbox = (index) => {
            this.setState({
                index,
                shown: true,
            });
        };
        this.hideLightbox = () => {
            this.setState({
                index: 0,
                shown: false,
            });
        };
    }
    state = {
        index: 0,
        shown: false,
    };
    render() {
        const { images } = this.props;
        const { index, shown } = this.state;
        return (
            <View
                style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                }}
            >
                <>{
                    images.map((image, idx) =>

                        <GalleryImage
                            index={idx}
                            key={idx}
                            onPress={this.showLightbox}
                            uri={image}
                        />
                    )
                }
                </>


            </View>
        );
    }
}
Gallery.propTypes = {
    images: PropTypes.array,
};

const Footer = ({navigation})=>{
    const [isLoading, setIsLoading] = useState(true)
    const [aspect,setAspect] = useState({})

    const WIDTH = useWindowDimensions()
    const {treatmentData} = useAppContext()
    const [isMobile, setIsMobile] = React.useState(false);
    const route = useRoute();
    const currentRouteName = route.name;
    const [showFoot,setShow]=useState(false)

    React.useEffect(() => {
        console.log('route',currentRouteName)
        if(currentRouteName==='Treatment'){
            setShow(true)
        }
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };
        if(isNativeApp()){
            setIsMobile(true)
        }

        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        return () => {
            // Remove event listener when component unmounts
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(()=>{
        console.log('aspect',treatmentData.aspect)
        /*
         if(Object.keys(treatmentData.aspect).length>0) {
             setAspect(treatmentData.aspect)
         }else{
             setAspect({})
         }*/
        setAspect(treatmentData.aspect)
    },[treatmentData])
if(!showFoot){
    return <></>
}
    return (
            <Box py={0} my={0} bottom={  {md:20,base:0}} zIndex={9999999999}  left={0} position={'fixed'}    ml={{md:5,base:1}} width={{base:'100%',md:'30%'}}>

                <Flex  flexDirection={'row'} color={'blue.600'} bg={"yellow.300"} p={2} flexWrap={'wrap'}>

                    {aspect.length===0? (
                    <>
                    {Config.testing&&<Text nativeID="counter2"></Text>}
                    <Text>
                        You have no Treatment Trail
                    </Text></>):(

                     <Flex nativeID="box-stack" flexDirection={'row'}    aligncontent={'flex-start'} justifyitems={'flex-start'} flexWrap={'wrap'}>   <Flex flexDirection={'row'} flexWrap={'wrap'} aligncontent={'flex-start'} justifyitems={'flex-start'}>
                        {aspect.map( (s,index)=> {
                               
                               return    <Box key={index} fontSize={'sm'} mr={2}><Text fontSize={'xs'} fontFamily={'mono'}>{s[0]}&nbsp;({s[1]>-1?s[1]:'' }) -&gt;</Text></Box>

                            }
                        )}


                     </Flex>
                         {Config.testing && 
                             <VStack>

                             <HStack nativeID="counter2" >B:{treatmentData.roundcount}/{treatmentData.attempts}/{treatmentData.impediment}</HStack><>|</>
                            </VStack>

                        }
                    </Flex>

                    )
                    }
                    </Flex>

            </Box>



   )

}
const ImageModal = ({url,setOpenImg,openImg,title})=>{

    const WIDTH = useWindowDimensions()
    const [height,setHeight] = useEffect(()=>{
        setHeight(0.75*WIDTH.width/WIDTH.scale)
    },[WIDTH.width,url])

    return (
        <Modal size={'full'} m={'auto'} isOpen={openImg} onClose={() => setOpenImg(false)} _backdrop={{

            _dark: {
                bg: "coolGray.800"
            },
            bg: "warmGray.50"
        }}>
              <Modal.Content width="100%">
                <Modal.CloseButton />
                <Modal.Header>{title}</Modal.Header>
                <Modal.Body>

                    <Image
                        style={{width: '100%',height: height}}
                        source={
                        {
                            uri: url
                        }
                    }
                           />
                </Modal.Body>

            </Modal.Content>
        </Modal>

    )
}
const VideoModal = ({url,setOpenVideo,openVideo,title})=>{

    return (
        <Modal overlayVisible={true}  closeOnOverlayClick={true} safeArea  size={"xl"} m={'auto'} pt={{base:'60%', md: '30%'}} isOpen={openVideo} onClose={() => setOpenVideo(false)} _backdrop={{
            _dark: {
                bg: "coolGray.800"
            },
            bg: "warmGray.50"
        }}>
            <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>{title}</Modal.Header>
                <Modal.Body minH={'480'}>

                    <VideoPlayer url={url}/>

                    </Modal.Body>

            </Modal.Content>
        </Modal>


    )
}
const VideoModalSheet = ()=>{
    const WIDTH = useWindowDimensions()
    const {videoUrl,videoTitle,dispatch} = useAppContext()
    const [openVideo,setOpenVideo] = useState(false)
    const [height,setHeight]=useState(450)

    useEffect(()=>{
        setOpenVideo(videoUrl?true:false)
        setHeight(WIDTH.width*0.75/WIDTH.scale)
    },[videoUrl,WIDTH.width])
    return (
        <Modal overlayVisible={true}  closeOnOverlayClick={true}  size={"full"} mt={300}   isOpen={openVideo} onClose={() =>

            dispatch({type:'VIDEO',data: {videoUrl:'', videoTitle: ''}})

        } _backdrop={{
            _dark: {
                bg: "coolGray.800"
            },
            bg: "warmGray.50"
        }}>
            <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>{videoTitle}</Modal.Header>
                <Modal.Body>

                    <VideoPlayer url={videoUrl} height={height}/>

                </Modal.Body>

            </Modal.Content>
        </Modal>
    )

}
const ImageModalSheet = ()=>{
   const {imgUrl,imgTitle,dispatch} = useAppContext()
   const [openVideo,setOpenVideo] = useState(false)
   const [height,setHeight] = useState(330)
   const WIDTH = useWindowDimensions()
    useEffect(()=>{
       setOpenVideo(imgUrl?true:false)
      setHeight(0.75*WIDTH.width/WIDTH.scale)
      // console.log('image',imgUrl)
   },[imgUrl,WIDTH.width])
    return (
        <Modal overlayVisible={true}  closeOnOverlayClick={true}  size={"full"}  mt={300} isOpen={openVideo} onClose={() =>

            dispatch({type:'IMAGE',data: {imgUrl:'', imgTitle: ''}})

        } _backdrop={{
            _dark: {
                bg: "coolGray.800"
            },
            bg: "warmGray.50"
        }}>
            <Modal.Content minH={300}>
                <Modal.CloseButton />
                <Modal.Header>{imgTitle}</Modal.Header>
                <Modal.Body minH={height}>
                    <Box minH={height}>
                    {imgUrl?( <Image
                        resizeMode={"cover"}
                        style={{width: '100%', height: height, minHeight: 300}}
                        source={
                            {
                                uri: imgUrl
                            }
                        }
                   alt={'token'}
                    />):(<Spinner/>)}
</Box>

                </Modal.Body>

            </Modal.Content>
        </Modal>
    )

}
export {Footer, Gallery,VideoModal,VideoModalSheet,ImageModalSheet}
