/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
/**
 * add id to all next buttons
 *
 */
import {Formik} from "formik";

import {Config} from "./Config";
import {ImageModalSheet, VideoModalSheet} from "./Components";
import {
    Radio,
    Box,
    Heading as Head,
    VStack,
    FormControl,
    Input,
    Button,
    Center,
    NativeBaseProvider,
    HStack,
    Link,
    Text,
    TextField,
    IconButton,
    Spinner,
    Icon,
    View,
    Card, Slider, Checkbox, Flex, useColorModeValue, Alert, AspectRatio, Image, useColorMode, Divider, Modal,
    Select,
    Pressable
} from "native-base";
import {Heading} from "./Heading";
import {createTreatmentPost, useAppContext, useText } from "./AppContextProvider";
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {useMutation} from "react-query";
import VideoPlayer from "./VideoPlayer";
import {useFocusEffect} from "@react-navigation/native";
import {Gallery} from "./Components";
import { Table, TableWrapper, Row, Rows, Col, Cols, Cell } from 'react-native-table-component';
import Content from "./Content";

import * as yup from 'yup';

const validationSchema = yup.object().shape({
    email: yup.string().email().required('Email is required.'),
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    //age: yup.number().required('Age is required').positive('Age must be a positive number'),
    phone: yup.string().required('Phone is required'),
    problem: yup.string().required('Problem Description is required'),
});





const SudsVal = ({maxValue,setSuds,step}) => {
    const [value, setValue] = useState(-1);
    const key= useRef(10)
   useEffect(()=>{

       if(key.current!==maxValue){


       }
       key.current=maxValue
        //#console.log#'changeval',maxValue)



    },[maxValue])
    useEffect(()=>{
        //#console.log#'value',value,'initsuds')

       /// setSuds(value)

    },[value])

    const setChange=useCallback((v)=>{

        let val = 0
        /*
        if(maxValue===100){
            val = Math.floor(v)*10
        }else if(maxValue===10){
            val = Math.floor(v)
        }else{
            val =   parseFloat((Math.floor(v)/10).toFixed(2))
        }*/
        setSuds(v)


    },[maxValue])
    return (
        <Box  width={{md:'400px', base: '200px' }} bg={'cyan.200'} overflowX={'hidden'}>
           
              <Input nativeID="sudsvalx"  w={'100%'} variant={"underlined"}  onChangeText={setChange}/>
        </Box>
    );
};


const RenderScaleBar = ({minValue,maxValue,step}) => {
    const scaleValues = useMemo(()=>{
        const r = [
            [.1,.2,.3,.4,.5,.6,.7,.8,.9],

            [1,2,3,4,5,6,7,8,9],
            [10,20,30,40,50,60,70,80,90]
        ]
       if(maxValue===1){
           return r[0]
       }
       if(maxValue===100){
           return  r[2]
       }

        return r[1]
    },[minValue,maxValue,step]);
    const trackWidth = 200;
    const trackLeftOffset = 15;
    const scaleBarWidth = trackWidth / (scaleValues.length - 1);
    return (
        <Flex flexDir={'row'} m={0} p={0} width={'100%'} bg={'yellow.50'} alignItems={'stretch'} justifyContent={'space-evenly'} flexDirection='row'>
            {scaleValues.map((val, idx) => (
                <Box p={0} m={0}
                    key={idx}
                    flexDirection='row'
                    alignItems='center'
                >
                    <Text>{val}</Text>
                </Box>
            ))}
        </Flex>
    );
};


const ScaleBarSlider = ({maxValue,setSuds,step}) => {
    const [value, setValue] = useState(-1);
    const key= useRef(10)
   useEffect(()=>{

       if(key.current!==maxValue){


       }
       key.current=maxValue
        //#console.log#'changeval',maxValue)



    },[maxValue])
    useEffect(()=>{
        //#console.log#'value',value,'initsuds')

       /// setSuds(value)

    },[value])

    const setChange=useCallback((v)=>{

        let val = 0
        if(maxValue===100){
            val = Math.floor(v)*10
        }else if(maxValue===10){
            val = Math.floor(v)
        }else{
            val =   parseFloat((Math.floor(v)/10).toFixed(2))
        }
        setSuds(val)


    },[maxValue])
    return (
        <Box  width={{md:'400px', base: '200px' }} bg={'cyan.200'} overflowX={'hidden'}>
            <Slider   width={'100%'}  defaultValue={-1}   colorScheme="red" minValue={0} maxValue={10} step={1} onChangeEnd={setChange} onChange={setChange}>
                <Slider.Track h={10}>
                    <Slider.FilledTrack />

                </Slider.Track>

                <Slider.Thumb />
            </Slider>

            {/*maxValue===1&& <Slider key={'key'+key.current} width={'100%'}  defaultValue={0}   colorScheme="red" minValue={0} maxValue={1} step={0.1} onChangeEnd={(v)=>{  setSuds(v);
            }} onChange={(v)=>{setSuds(v)}}>
                <Slider.Track h={10}>
                    <Slider.FilledTrack />

                </Slider.Track>

                <Slider.Thumb />
            </Slider>*/}


            {/*maxValue===10&& <Slider wkey={'key2'+key.current}  width={'100%'} defaultValue={-1}   colorScheme="green" minValue={0} maxValue={10} step={1} onChangeEnd={(v)=>{ v<1? setValue(v):setValue(Math.floor(v))}} onChange={(v)=>{setValue(Math.floor(v))}}>
                <Slider.Track h={10}>
                    <Slider.FilledTrack />

                </Slider.Track>

                <Slider.Thumb />
            </Slider>*/}
            {/*maxValue===100&& <Slider key={'key3'+key.current} width={'100%'} defaultValue={-20}   colorScheme="yellow" minValue={0} maxValue={100} step={10} onChangeEnd={(v)=>{ v<1? setValue(v):setValue(Math.floor(v))}} onChange={(v)=>{setValue(Math.floor(v))}}>
                <Slider.Track h={10}>
                    <Slider.FilledTrack />

                </Slider.Track>

                <Slider.Thumb />
            </Slider>*/}


            <RenderScaleBar step={step} minValue={0} maxValue={maxValue}/>
        </Box>
    );
};



const canShowHeaderRight = (treatmentData)=>{

    //#console.log#'canshowheaderright',treatmentData.process)
    return (treatmentData.process !== 'suds'&& treatmentData.process!=='aspect')
}

const ThisFar = ({navigation,treatmentData,dispatch})=>{
    const [openImg,setOpenImg] = useState(false)
    const [openVideo,setOpenVideo] = useState(false)


    const [loading,setLoading ] = useState(true)
    const [maxVal,setMaxVal] = useState(10)
    const [step,setStep] = useState(1)
    const [showPanic,setShowPanic] = useState(false)
    const [images, setImages]=useState(['','','',''])
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
            //    //#console.log#'treatment done',data)
            dispatch({type:'PROCESS_TREATMENT', data})

        },
        onError: error => {
            dispatch({type: 'ERROR', error: true})
            console.log('error in trea',error)
        }

    })

    useEffect(()=>{
        //  console.log('Taps Effect',treatmentData.images)
        setImages([treatmentData.images[0],treatmentData.images[1]])
    },[treatmentData])
    const setNextStep= useCallback(()=>{
        createTreatment({})

    },[createTreatment])


    const topRef = useRef()
    const imgRef = useRef()


    return (
        <View
            ref={topRef}
        >
            <View
                display={'flex'}

                flexGrow={1}
                flexShrink={0}
                flexWrap={'no-wrap'}
                flexDirection={{base: 'column', md: 'row'}}
                w="100%"
                bg={'cyan.200'}


                justifyContent={'space-around'}
                alignItems={'stretch'}
                alignContent={'stretch'}
                justifyItems={'space-around'}
            >


                <Box display={'inline-block'}   flexGrow={1} bg={'green.200'} flexShrink={0}  justifyContent={'center'} flexBasis={'50%'} height={{base: '330px',md: '100%'}} >


                    <Flex  flexDir={'column'} alignItems={'stretch'} justifyContent={'space-evenly'}   flex={1} flexDirection={'column'} h={'100%'}>
                        <Text variant={'h2'} color={useColorModeValue('blue.500', 'green.100')}>
                            {treatmentData.problem}
                        </Text>

                        <Head color={useColorModeValue('blueGray.700', 'blueGray.100')}>
                            {treatmentData.treatmentHeader}
                        </Head>

                        <Text variant={'body'} color={useColorModeValue('blueGray.500', 'blueGray.200')}>
                            {treatmentData.treatmentDescription}

                        </Text>


                        <HStack justifyContent={'flex-end'} gap={5} mr={5} space={4}>
                            {/*
                           <Button
                                style={{marginLeft: 10}}
                                variant={"solid"}
                                colorScheme={"primary"}
                                isLoading={creatingTreatment}
                                spinnerPlacement="end"
                                disabled={creatingTreatment}
                                onPress={() => {
                                    createTreatment({level: 11})
                                }}

                            >Call GHOSTBUSTER</Button>
*/}
                            <Button isLoading={creatingTreatment} variant="solid" colorScheme="blue" p={0} minH={0} onPress={e=>createTreatment({})}
                                    rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'lg'}/>}
                                    ml={1}
                                    p={3}
                                    size={'lg'}
                            >
                                OK
                            </Button>
                        </HStack>
                    </Flex>

                </Box>
                {treatmentData.imgTitle && <Flex flexGrow={1} flexBasis={'50%'} flexShrink={0} bg={'yellow.300'}>
                    <AspectRatio ratio={2 / 1}>
                        <Link
                            onPress={() => {
                                dispatch({type: 'IMAGE', data: {imgUrl: images[0], imgTitle: treatmentData.imgTitle}})
                                //  setOpenVideo(true)
                                //topRef.current.scrollTo({x:0,y:100,animated:false})
                            }}>

                            <Image
                                width={'100%'}
                                source={{
                                    uri: images[0],
                                }}
                                alt={treatmentData.imgTitle ?? 'Title'}
                            />
                        </Link>
                    </AspectRatio>
                    <AspectRatio ratio={2 / 1}>
                        <Link
                            onPress={() => {
                                dispatch({
                                    type: 'VIDEO',
                                    data: {videoUrl: treatmentData.video, videoTitle: treatmentData.videoTitle}
                                })
                                //  setOpenVideo(true)
                                // topRef.current.scrollTo(0,0)
                                //#console.log#'scrolled')
                            }}>
                            <Image

                                ref={imgRef}
                                width={'100%'}
                                source={{
                                    uri: images[1],
                                }}
                                alt={treatmentData.videoTitle ?? 'NoVideo'}
                            />
                        </Link>
                    </AspectRatio>

                </Flex>
                }


            </View>
        </View>




    )



}
const Initialize = ({treatmentData,dispatch})=>{
    const { mutate: createTreatment, isLoading: creatingTreatment, isError } = useMutation(createTreatmentPost, {
        onSuccess: data => {
            //#console.log#'setstack',data['stack'])
            data['stack']= []

            const dt = {...{
                    aspect:{},
                    attempt: {},
                    process: '',
                    problem: '',
                    mainprocess: '',
                    aspectof: '',
                    video: '',
                    images: '',
                    treatmentHeader: '',


                },...data}

            //#console.log#'treatmentdone finish',dt)
            dispatch({type:'PROCESS_TREATMENT',data:dt})
            // navigation.navigate('Treatment')
        },
        onError: error => {

            dispatch({type: 'ERROR', error: true})
            //#console.log#'error in trea',error)
        }

    })

    useEffect(()=>{
       if(!treatmentData.process){
           createTreatment({init:'init'})
       }
    },[treatmentData.process,isError])

    return (
        <>Initing.</>
    )
}


const Finish = ({navigation,treatmentData,dispatch})=>{
    const [text,setText] = useState('')
    const [loading,setLoading ] = useState(true)
    const [stack,setStack] = useState([])
    const [showbtn,setShowbtn]=useState(false)
    const [stage,setStage] = useState(false)
    const [tableData,setTableData] = useState([])
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
           setStack(data['stack'])
           //#console.log#'setstack',data['stack'])
            data['stack']=null

           const dt = {...{
                    aspect:{},
                    attempt: {},
                    process: '',
                    problem: '',
                    mainprocess: '',
                    aspectof: '',
                    video: '',
                    images: '',
                    treatmentHeader: '',


                },...data}

            //#console.log#'treatmentdone finish',dt)
            dispatch({type:'PROCESS_TREATMENT',data:dt})
             // navigation.navigate('Treatment')
        },
        onError: error => {
            //#console.log#'error in trea',error)
        }

    })

  const tableHead =      ['Symptom','Start' ,'Suds ASP', 'Impediments']
  useEffect(()=>{
      console.log('#treatmentData',treatmentData['stack'],treatmentData['thisfar'])
  if(treatmentData.stage){
///        navigation.navigate('Home')
      ///setStage(true)
  }else {

  }
      setShowbtn(treatmentData['thisfar'])
      setStack(treatmentData['stack'])


  },[navigation, treatmentData])
   useEffect(()=>{
       //#console.log#'stack',stack)
       const tableData = []
       for(let i in stack){
            const treatment = stack[i]
            if (!treatment.name){
                continue
            }
            //#console.log#'ix',i,'treatment',treatment)
            const row = [
                ' '.repeat(treatment.depth)+treatment.name,
                treatment.startsuds,
                Math.max(treatment.endsuds,0),
                //treatment.round,
                //treatment.max,
                treatment.maximpedimentname

            ]

           tableData.push(row)

        }
       setTableData(tableData)
       //#console.log#'setloading',loading)
      setLoading(false)
   },[stack])
   const xcolor=  useColorModeValue('blueGray.700', 'blueGray.100')
   return (

        <>


            <Box style={{display: "flex", flexdirection: "column"}}>

            {loading && <Spinner size={"lg"} ></Spinner>}
            {!loading &&  <Table borderStyle={{borderWidth: 2, borderColor: '#c8e1ff'}}>
                <Row data={tableHead} style={{ height: 40, backgroundColor: '#f1f8ff' }} />
                <Rows data={tableData} />
            </Table>}



            <Box nativeID="box-report" style={{display: "flex", flexdirection: "column"}}>
                <Head color={xcolor} size={"sm"}>
                    {treatmentData.header}
                </Head>



            </Box>

            </Box>
        </>
    )
}

const GhostBuster = ({navigation,treatmentData,dispatch})=>{
    const [text,setText] = useState('')
    const [suds,setSuds] = useState(-2)
    const [stage,setStage]=useState(false)
    const [loading,setLoading ] = useState(true)
    const { user } = useAppContext()

    const [back,setBack] = useState(false)
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
            //#console.log#'treatmentdone ghostbuster ',data)
            dispatch({type:'PROCESS_TREATMENT',data})
        },
        onError: error => {

            dispatch({type: 'ERROR', error: true})
            console.log('error in trea',error)
        }

    })

   useEffect(()=>{
       if(treatmentData.stage){
           setStage(true)
       }else{
           setStage(false)
       }
       //#console.log#'ghostbuster data',treatmentData)
   },[treatmentData])

    const topRef = useRef()
    const imgRef = useRef()


    return (
        <View
            ref={topRef}
        >
            <View
                display={'flex'}

                flexGrow={1}
                flexShrink={0}
                flexWrap={'no-wrap'}
                flexDirection={{base: 'column', md: 'row'}}
                w="100%"
                bg={'cyan.200'}


                justifyContent={'space-around'}
                alignItems={'stretch'}
                alignContent={'stretch'}
                justifyItems={'space-around'}
            >


                <Box display={'inline-block'}   flexGrow={1} bg={'green.200'} flexShrink={0}  justifyContent={'center'} flexBasis={'50%'} height={{base: '330px',md: '100%'}} >


                    <Flex  flexDir={'column'} alignItems={'stretch'} justifyContent={'space-evenly'}   flex={1} flexDirection={'column'} h={'100%'}>

                        <Head color={useColorModeValue('blueGray.700', 'blueGray.100')}>
                            {treatmentData.treatmentHeader}
                        </Head>

                        <Text variant={'body'} color={useColorModeValue('blueGray.500', 'blueGray.200')}>
                            {treatmentData.treatmentDescription}

                        </Text>

                        <Box style={{borderWidth: 1}} bg={"white"} maxW={250} justifyContent={'center'}>
                            <Formik
                                initialValues={{
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    phone: user.phone??11111111,
                                    email: user.email,
                                    problem: 'problem description test filled up',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={async (values) => {
                                      await  createTreatment(values)
                                    //onSubmit(values);
                                }}
                            >
                                {({isSubmitting, handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                                    <View>
                                        <FormControl isRequired isInvalid={touched.firstName && errors.firstName}>
                                            <FormControl.Label>First Name</FormControl.Label>
                                            <Input
                                                nativeID="firstName"
                                                onChangeText={handleChange('firstName')}
                                                onBlur={handleBlur('firstName')}
                                                value={values.firstName}
                                            />
                                            {touched.firstName && errors.firstName && (
                                                <FormControl.ErrorMessage>{errors.firstName}</FormControl.ErrorMessage>
                                            )}
                                        </FormControl>

                                        <FormControl isRequired isInvalid={touched.lastName && errors.lastName}>
                                            <FormControl.Label>Last Name</FormControl.Label>
                                             <Input
                                                nativeID="lastName"
                                                onChangeText={handleChange('lastName')}
                                                onBlur={handleBlur('lastName')}
                                                value={values.lastName}
                                            />
                                            {touched.lastName && errors.lastName && (
                                                <FormControl.ErrorMessage>{errors.lastName}</FormControl.ErrorMessage>
                                            )}
                                        </FormControl>
                                        <FormControl isRequired isInvalid={touched.lastName && errors.lastName}>
                                            <FormControl.Label>Email</FormControl.Label>
                                            <Input
                                                onChangeText={handleChange('email')}
                                                onBlur={handleBlur('email')}
                                                value={values.email}
                                            />
                                            {touched.lastName && errors.lastName && (
                                                <FormControl.ErrorMessage>{errors.lastName}</FormControl.ErrorMessage>
                                            )}
                                        </FormControl>


                                        {/*
                                        <FormControl isRequired isInvalid={touched.age && errors.age}>
                                            <FormControl.Label>Age</FormControl.Label>
                                            <Input
                                                onChangeText={handleChange('age')}
                                                onBlur={handleBlur('age')}
                                                value={values.age}
                                                keyboardType="numeric"
                                            />
                                            {touched.age && errors.age && (
                                                <FormControl.ErrorMessage>{errors.age}</FormControl.ErrorMessage>
                                            )}
                                        </FormControl>
*/}
                                        <FormControl isRequired isInvalid={touched.phone && errors.phone}>
                                            <FormControl.Label>Phone</FormControl.Label>
                                            <Input
                                                nativeID="phone"
                                                onChangeText={handleChange('phone')}
                                                onBlur={handleBlur('phone')}
                                                value={values.phone}
                                                keyboardType="phone-pad"
                                            />
                                            {touched.phone && errors.phone && (
                                                <FormControl.ErrorMessage>{errors.phone}</FormControl.ErrorMessage>
                                            )}
                                        </FormControl>

                                        <FormControl isRequired isInvalid={touched.problem && errors.problem}>
                                            <FormControl.Label>Problem Description</FormControl.Label>
                                            <Input
        nativeID="problem"
                                    onChangeText={handleChange('problem')}
                                                onBlur={handleBlur('problem')}
                                                value={values.problem}
                                                multiline
                                            />
                                            {touched.problem && errors.problem && (
                                                <FormControl.ErrorMessage>{errors.problem}</FormControl.ErrorMessage>
                                            )}
                                        </FormControl>

                                        <Button
                                            style={{marginLeft: 10}}
nativeID="btn-next-gb"
                                            variant={"solid"}
                                            colorScheme={"primary"}
                                            isLoading={isSubmitting || creatingTreatment}
                                            spinnerPlacement="end"
                                            disabled={isSubmitting || creatingTreatment}
                                            leftIcon={<Icon as={MaterialIcons}  name='arrow-right'/>}
                                            onPress={handleSubmit}
                                            size={'md'}
                                            maxW={150}
                                            p={3}

                                        >Call GhostBuster</Button>


                                    </View>
                                )}
                            </Formik>

                        </Box>


                        <HStack justifyContent={'flex-end'} gap={5} mr={5} space={4}>



                       </HStack>
                    </Flex>

                </Box>


            </View>
        </View>
    )



}



const Stuck = ({navigation,treatmentData,dispatch})=>{
    const [openImg,setOpenImg] = useState(false)
    const [openVideo,setOpenVideo] = useState(false)

    const [loading,setLoading ] = useState(true)
    const [maxVal,setMaxVal] = useState(10)
    const [step,setStep] = useState(1)
    const [showPanic,setShowPanic] = useState(false)
    const [images, setImages]=useState(['','','',''])
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
        //    //#console.log#'treatment done',data)
            dispatch({type:'PROCESS_TREATMENT', data})

        },
        onError: error => {
            console.log('error in trea',error)
        }

    })

    useEffect(()=>{
      //  console.log('Taps Effect',treatmentData.images)
        setImages([treatmentData.images[0],treatmentData.images[1]])
    },[treatmentData])
    const setNextStep= useCallback(()=>{
        createTreatment({})

    },[createTreatment])


    const topRef = useRef()
    const imgRef = useRef()


    return (
        <View
            ref={topRef}
        >
            <View
                display={'flex'}

                flexGrow={1}
                flexShrink={0}
                flexWrap={'no-wrap'}
                flexDirection={{base: 'column', md: 'row'}}
                w="100%"
                bg={'cyan.200'}


                justifyContent={'space-around'}
                alignItems={'stretch'}
                alignContent={'stretch'}
                justifyItems={'space-around'}
            >


                <Box display={'inline-block'}   flexGrow={1} bg={'green.200'} flexShrink={0}  justifyContent={'center'} flexBasis={'50%'} height={{base: '330px',md: '100%'}} >


                    <Flex  flexDir={'column'} alignItems={'stretch'} justifyContent={'space-evenly'}   flex={1} flexDirection={'column'} h={'100%'}>
                        <Text variant={'h2'} color={useColorModeValue('blue.500', 'green.100')}>
                           Stuck at {treatmentData.problem}
                        </Text>

                        <Head color={useColorModeValue('blueGray.700', 'blueGray.100')}>
                            {treatmentData.treatmentHeader}
                        </Head>

                        <Text variant={'body'} color={useColorModeValue('blueGray.500', 'blueGray.200')}>
                            {treatmentData.treatmentDescription}

                        </Text>


                        <HStack justifyContent={'flex-end'} gap={5} mr={5} space={4}>
                            {/*
                           <Button
                                style={{marginLeft: 10}}
                                variant={"solid"}
                                colorScheme={"primary"}
                                isLoading={creatingTreatment}
                                spinnerPlacement="end"
                                disabled={creatingTreatment}
                                onPress={() => {
                                    createTreatment({level: 11})
                                }}

                            >Call GHOSTBUSTER</Button>
*/}
                            <Button variant="solid" colorScheme="blue" p={0} minH={0} onPress={setNextStep}
                                    rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'md'}/>}
                                    ml={1}
                                    p={3}
                                    size={'md'}
                                    isLoading={creatingTreatment}
                                    isDisabled={creatingTreatment}
nativeID="btn-next-imp" //from btn-next-stuck
                            >
                                Next
                            </Button>
                        </HStack>
                    </Flex>

                </Box>
                <Flex   flexGrow={1} flexBasis={'50%'} flexShrink={0}  bg={'yellow.300'}    >
                    <AspectRatio ratio={2/1}>
                        <Link
                            onPress={()=>{
                                dispatch({type: 'IMAGE',data:{imgUrl: images[0], imgTitle: treatmentData.imgTitle}})
                                //  setOpenVideo(true)
                                //topRef.current.scrollTo({x:0,y:100,animated:false})
                            }}>

                            <Image
                                width={'100%'}
                                source={{
                                    uri: images[0],
                                }}
                                alt={treatmentData.imgTitle??'Title'}
                            />
                        </Link>
                    </AspectRatio>
                    <AspectRatio ratio={2/1}>
                        <Link
                            onPress={()=>{
                                dispatch({type: 'VIDEO',data:{videoUrl: treatmentData.video, videoTitle: treatmentData.videoTitle}})
                                //  setOpenVideo(true)
                                // topRef.current.scrollTo(0,0)
                                //#console.log#'scrolled')
                            }}>
                            <Image

                                ref={imgRef}
                                width={'100%'}
                                source={{
                                    uri: images[1],
                                }}
                                alt={treatmentData.videoTitle??'NoVideo'}
                            />
                        </Link>
                    </AspectRatio>

                </Flex>


            </View>
        </View>




    )



}
const Impediments = ({navigation,treatmentData,dispatch})=>{
    const [openImg,setOpenImg] = useState(false)
    const [openVideo,setOpenVideo] = useState(false)

    const [loading,setLoading ] = useState(true)
    const [maxVal,setMaxVal] = useState(10)
    const [step,setStep] = useState(1)
    const [showPanic,setShowPanic] = useState(false)
    const [images, setImages]=useState(['','','',''])
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
         //   console.log('treatment done',data)
            dispatch({type:'PROCESS_TREATMENT', data})

        },
        onError: error => {
            console.log('error in trea',error)
        }

    })

    useEffect(()=>{
        //#console.log#'Taps Effect',treatmentData.images)
        setImages([treatmentData.images[0],treatmentData.images[1]])
    },[treatmentData])
    const setNextStep= useCallback(()=>{
        createTreatment({})

    },[createTreatment])


    const topRef = useRef()
    const imgRef = useRef()


    return (
        <View
            ref={topRef}
        >
            <View
                display={'flex'}

                flexGrow={1}
                flexShrink={0}
                flexWrap={'no-wrap'}
                flexDirection={{base: 'column', md: 'row'}}
                w="100%"
                bg={'cyan.200'}


                justifyContent={'space-around'}
                alignItems={'stretch'}
                alignContent={'stretch'}
                justifyItems={'space-around'}
            >


                <Box display={'inline-block'}   flexGrow={1} bg={'green.200'} flexShrink={0}  justifyContent={'center'} flexBasis={'50%'} height={{base: '330px',md: '100%'}} >


                    <Flex  flexDir={'column'} alignItems={'stretch'} justifyContent={'space-evenly'}   flex={1} flexDirection={'column'} h={'100%'}>
                        <Text variant={'h2'} color={useColorModeValue('blue.500', 'green.100')}>
                            {treatmentData.problem}
                        </Text>

                        <Head color={useColorModeValue('blueGray.700', 'blueGray.100')}>
                            {treatmentData.treatmentHeader}
                        </Head>

                        <Text variant={'body'} color={useColorModeValue('blueGray.500', 'blueGray.200')}>
                            {treatmentData.treatmentDescription}

                        </Text>


                        <HStack justifyContent={'flex-end'} gap={5} mr={5} space={4}>
            {['collarbone','shower','fast','changepos'].includes(treatmentData.process)
                            &&
                            <Button
                                style={{marginLeft: 10}}
                                variant={"solid"}
                                colorScheme={"primary"}
                                isLoading={creatingTreatment}
                                spinnerPlacement="end"
                                disabled={creatingTreatment}
                                leftIcon={<Icon as={MaterialIcons} name='arrow-right'/>}
                                onPress={() => {
                                    createTreatment({callghost: 1})
                                }}

nativeID="btn-next-dogb"
                            >Call GHOSTBUSTER</Button>
                            }

                            <Button size={'md'} variant="solid" colorScheme="blue" p={0} minH={0} onPress={setNextStep}
                                    rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'md'}/>}
                                    ml={1} p={3}
                                    isLoading={creatingTreatment}
                                    isDisabled={creatingTreatment}

nativeID="btn-next-imp"
                            >
                                Next
                            </Button>
                        </HStack>
                    </Flex>

                </Box>
                <Flex   flexGrow={1} flexBasis={'50%'} flexShrink={0}  bg={'yellow.300'}    >
                    <AspectRatio ratio={2/1}>
                        <Link
                            onPress={()=>{
                                dispatch({type: 'IMAGE',data:{imgUrl: images[0], imgTitle: treatmentData.imgTitle}})
                                //  setOpenVideo(true)
                                //topRef.current.scrollTo({x:0,y:100,animated:false})
                            }}>

                            <Image
                                width={'100%'}
                                source={{
                                    uri: images[0],
                                }}
                                alt={treatmentData.imgTitle??'Title'}
                            />
                        </Link>
                    </AspectRatio>
                    <AspectRatio ratio={2/1}>
                        <Link
                            onPress={()=>{
                                dispatch({type: 'VIDEO',data:{videoUrl: treatmentData.video, videoTitle: treatmentData.videoTitle}})
                                //  setOpenVideo(true)
                                // topRef.current.scrollTo(0,0)
                                //#console.log#'scrolled')
                            }}>
                            <Image

                                ref={imgRef}
                                width={'100%'}
                                source={{
                                    uri: images[1],
                                }}
                                alt={treatmentData.videoTitle??'NoVideo'}
                            />
                        </Link>
                    </AspectRatio>

                </Flex>


            </View>
        </View>




    )



}

const Suds = ({navigation, isLoading,submit})=>{
    const [maxVal,setMaxVal] = useState(10)
    const [minval,setMinVal] = useState(0)
    const [step,setStep] = useState(1)
    const [val,setVal] = useState(-1)
    const [defVal,setDefVal]=useState(-1)
    const [divs,setDivs]=useState([0.1,1,10])
    const [idx,setIdx]=useState(2)
    const [sudsText,setSudsText] = useState('')
    
    const { isLoading: sudsLoading, isError: sudsError, data: sudsData } = useText('suds')
   
    useEffect(()=>{

        if(sudsData){
            setSudsText(sudsData.sudsfooter)            
        }

    },[sudsData]);
   useEffect(()=>{
   //     console.log('changediv',divs,'idx',idx)
        if(idx===1){
            let valx = divs[0]
                //   console.log('divisor',valx,'divs',divs)
            setVal(val=>{
                //#console.log#'orig',val,'divisor',valx)
                return   valx*val
            })

            setDivs([1,10,100])
            setMaxVal(1)
            //   setMinVal(0)
            setStep(0.1)

        }else if(idx===3){
            let valx = divs[2]
            //#console.log#'div2',divs,'divisor0',valx)
            setVal(val=>{
                //#console.log#'orig2',val,'divisor',valx)
                return   valx*val
            })
            setDivs([0.01,.1,1])
            setMaxVal(100)
            setStep(10)


        }else{
            let valx = divs[1]
            //#console.log#'divn',divs,'divisor0',valx)
            setVal(val=>{
                //#console.log#'orign',val,'divisor',valx)
                return   valx*val
            })

            setDivs([0.1,1,10])
            setMaxVal(10)
            setStep(1)


        }

    },[idx])
    const setLabel = useCallback((v)=>{
        //#console.log#'withdiv',divs)
        v=parseInt(v)
        if(v===1){
          //setVal(-1)
        }else if(v===3){

            ///  setMinVal(0)
            //  setVal(-1)
       }else{
            //   setMinVal(0)
           //  setVal(-1)
       }
       setIdx(v)
    }
    ,[divs])

    return (
        <Flex gap={10} flexDir={'column'} alignItems={'center'} justifyContent={'flex-start'}>


    <Box><Text bold nativeID="sudsval">Current Suds: {val>=0? val:'--'}</Text></Box>
    <Flex flexDir={'column'} alignItems={'center'} gap={5} w={'xl'}>

       {Config.testing && <SudsVal setSuds={setVal}/> }{!Config.testing &&
       
       
       <ScaleBarSlider  maxValue={maxVal} minValue={0} step={step} setSuds={setVal}/>}
        <Radio.Group name="exampleGroup" defaultValue="2" accessibilityLabel="pick a suds range" onChange={(v)=>{
            setLabel(v)
        }}
          >
        <HStack  alignItems={{
                base: "flex-start",
                md: "center"
            }} space={4} w="75%" maxW={400}>
                <Radio value="1" colorScheme="red" size="sm" my={1}>
                    <Text fontSize={'xs'} fontWeight={'thin'}> 0- &lt;1</Text>
                </Radio>
                <Radio value="2" colorScheme="green" size="md" my={1}>
                   <Text fontSize={'xs'} fontWeight={'thin'}> 0 - &lt;=10</Text>
                </Radio>
                <Radio value="3" colorScheme="yellow" size="lg" my={1}>
                    <Text fontSize={'xs'} fontWeight={'thin'}> 0 - &lt;100</Text>
                </Radio>
            </HStack>
        </Radio.Group>

    </Flex> {/* sliderbox */}

    <Box>
        <Button
            style={{marginLeft: 10}}
            variant={"solid"}
            colorScheme={"primary"}
            isLoading={isLoading}
            isDisabled={isLoading}
            spinnerPlacement="end"
            _disabled={{backgroundColor: "coolGray.600"}}
            disabled={isLoading||val<0}
            leftIcon={<Icon as={MaterialIcons}  name='arrow-right'/>}
            onPress={()=>{ submit(val) }}
            size={'md'}
            p={3}

nativeID="btn-next-suds"
>Next</Button>

    </Box>
            <Box>
                <Text variant='body'>
                                {sudsText}
                             </Text>
                </Box>

            </Flex>
    )

}
const ErrorModal = ()=>{
   const { error, dispatch } = useAppContext()

    useEffect(()=>{
       if(error){
           setTimeout(()=>{
               dispatch({type: 'ERROR', error: false})
           },2000)

       }


    },[error])

    return (

        <Modal size={"xs"} pt={200} m={'auto'} isOpen={error} onClose={() => {

        }} _backdrop={{

            _dark: {
                bg: "coolGray.800"
            },
            bg: "warmGray.50"
        }}>
            <Modal.Content width="100%">
                <Modal.CloseButton />
                <Modal.Header>Error</Modal.Header>
                <Modal.Body>
                    Server Error
               </Modal.Body>

            </Modal.Content>
        </Modal>

    )
}
const SudsView = ({navigation})=>{

    const [suds,setSuds]=useState(-1)
    const {dispatch,treatmentData} = useAppContext()
    const [panic,setPanic]=useState(false)
    const [conf,setConf]=useState(false)
    const [sudsTitle,setSudsTitle] = useState('')
    const [sudsText,setSudsText] = useState('')
    const { isLoading: textLoading, isError: textError, data: textData } = useText('suds')
    
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
        //    //#console.log#'treatmentdone sudsview ',data)
            dispatch({type:'PROCESS_TREATMENT',data})
            ///  navigation.navigate(data.process)
        },
        onError: error => {
            console.log('error in trea',error)
        }

    })
    useEffect(()=>{
        if(textData){
            setSudsText(textData.text)
            setSudsTitle(textData.title)
        }

    },[textData])
    useEffect(()=>{
       // console.log('sudsvew',treatmentData)
        setSuds(-1)

    },[treatmentData])

    const closePanic = useCallback(()=>{
        //#console.log#'panic',suds)
        createTreatment({suds:suds})
        setPanic(false)
       /// setConf(false)
    },[createTreatment, suds])

    const submitSuds=useCallback((val)=>{
        val = parseFloat(val)
        if(val<0){
            return
        }
       /*
        if(val===0){

            setConf(true)
            setSuds(val)
            return;
        }*/

        if(treatmentData.suds >-1 && val > treatmentData.suds){

            console.log('treatmentDatasuds',treatmentData.suds,'val',val)
            setPanic(true)
            setSuds(val)
        }else{

            createTreatment({suds:val})
        }

    },[ treatmentData.suds])

    return (

        <View style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <Text>{treatmentData.treatmentHeader}</Text>
            <Suds navigation={navigation} treatmentData={treatmentData} isLoading={creatingTreatment||panic}  submit={submitSuds}
        />
            <Modal size={"xs"} pt={300} m={'auto'} isOpen={panic} onClose={() => closePanic()} _backdrop={{

                _dark: {
                    bg: "coolGray.800"
                },
                bg: "warmGray.50"
            }}>
                <Modal.Content width="100%">
                    <Modal.CloseButton  nativeID="btn-panic-close"/>
                    <Modal.Header>{sudsTitle}</Modal.Header>
                    <Modal.Body>
                        {sudsText}
                   </Modal.Body>

                </Modal.Content>
            </Modal>

        </View>
    )

}


const ModAspect = ({navigation,treatmentData,dispatch})=>{
    const [text,setText] = useState('')
    const [suds,setSuds] = useState(-2)
    const [loading,setLoading ] = useState(true)
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
            //   console.log('treatmentdone aspect',data)
            dispatch({type:'PROCESS_TREATMENT',data})
            ///  navigation.navigate(data.process)
        },
        onError: error => {
            console.log('error in trea',error)
        }

    })

//        const pref='sss'
    //      const suf = 'sss'


    const { treatmentHeader, header,aspect } = treatmentData
    const gotoSuds = useCallback(()=>{

        //#console.log#'current aspect at Aspect',aspect)
        const keys =  Object.keys(aspect)
        for(let i in keys){
            //#console.log#'aspect',keys[i],'text',i)
            if(keys[i].toLowerCase() !== text.toLowerCase())
            {
                setSuds(-1)
                return
            }
        }

    },[text,aspect])
    const [service,setService]= useState('')
    const symptoms = ["Headache", "pulsing pain", "pain on right side", "dullness on right side", "numbness on right side", "neck tension", "Huyagonnacall", "pain in centre*", "dull pain","compression*","eye discomfort","tight muscles*","sholdier tension*"]


    useEffect(()=>{
        setText(service)
    },[service])

    //#console.log#'aspect',aspect)
   return (
        <View style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            {suds === -2?


                (
                    <Flex bg={"gray.100"} gap={10} mt={5} flexGrow={1} flexDirection={'column'} alignItems={'space-evenly'} >
                        <Box>
                            <Text>{header}</Text>
                        </Box>
                        <Box style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", margin: 10}}>

                            <Text mr={1} >{treatmentHeader[0]}</Text>
                            <Box maxW="500">
                                <Input variant={"underlined"} defaultValue={service} size={{base:"xs","sm":"md"}} onChangeText={setText}/>
                                {/*
                                    <Select selectedValue={service} minWidth="200" accessibilityLabel="Choose" placeholder="Choose" _selectedItem={{
                                        bg: "teal.600",

                                    }} mt={1} onValueChange={itemValue => setService(itemValue)}>
                                        {symptoms.map(s=><Select.Item label={s} value={s} />)}
                                    </Select>
                                */}
                            </Box>

                            <Text>{treatmentHeader[1]}</Text>
                            <Button
                                style={{marginLeft: 10}}
                                variant={"solid"}
                                isLoading={creatingTreatment}
                                spinnerPlacement="end"
                                disabled={creatingTreatment|| text.length<3}
                                leftIcon={<Icon as={MaterialIcons}  name='arrow-right'/>}
                                onPress={gotoSuds}
                                size={'md'}
                                p={3}
                            >Next</Button>

                        </Box>
                    </Flex>


                ):
                (


                    <Flex bg={"gray.100"} gap={10} mt={5} flexGrow={1} flexDirection={'column'} alignItems={'space-evenly'} >

                        <Text>{treatmentHeader[0]} {text} {treatmentHeader[1]}</Text>

                        <Suds  navigation={navigation}  isLoading={creatingTreatment} submit={(val)=>{
                            createTreatment({name: text,suds: val, nest:1})
                        }
                        }/>

                    </Flex>
                )

            }
        </View>
    )
}

const Completex = ({navigation,treatmentData,dispatch})=>{
    const [text,setText] = useState('')
    const [suds,setSuds] = useState(-2)
    const [loading,setLoading ] = useState(true)
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
            //   console.log('treatmentdone aspect',data)
            dispatch({type:'PROCESS_TREATMENT',data})
            ///  navigation.navigate(data.process)
        },
        onError: error => {
            console.log('error in trea',error)
        }

    })

//        const pref='sss'
    //      const suf = 'sss'


    const { treatmentHeader, header,treatmentDescription } = treatmentData


   //#console.log#'aspect',aspect)
   return (
        <View style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                   <Flex bg={"gray.100"} gap={10} mt={5} flexGrow={1} flexDirection={'column'} alignItems={'space-evenly'} >
                        <Box>
                            <Text>{header}</Text>
                        </Box>
                        <Box style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", margin: 10}}>

                            <Text mr={1} >{treatmentHeader}</Text>

                            <Button
                                style={{marginLeft: 10}}
                                variant={"solid"}
                                isLoading={creatingTreatment}
                                spinnerPlacement="end"
                                disabled={creatingTreatment}
                                leftIcon={<Icon as={MaterialIcons}  name='arrow-right'/>}
                                onPress={createTreatment({complete: 'yes'})}
                                size={'md'}
                                p={3}
                            >Next</Button>

                        </Box>
                    </Flex>






        </View>
    )
}

const Aspect = ({navigation,treatmentData,dispatch})=>{
    const [text,setText] = useState('')
    const [suds,setSuds] = useState(-2)
    const [loading,setLoading ] = useState(true)
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
         //   console.log('treatmentdone aspect',data)
            dispatch({type:'PROCESS_TREATMENT',data})
            ///  navigation.navigate(data.process)
        },
        onError: error => {
            console.log('error in trea',error)
        }

    })

//        const pref='sss'
    //      const suf = 'sss'


   const { treatmentHeader, header,aspect } = treatmentData
    const gotoSuds = useCallback(()=>{

        //#console.log#'current aspect at Aspect',aspect)
     const keys =  Object.keys(aspect)
       for(let i in keys){
           //#console.log#'aspect',keys[i],'text',i)
           if(keys[i].toLowerCase() !== text.toLowerCase())
           {
               setSuds(-1)
           return
           }
       }

    },[text,aspect])
    const [service,setService]= useState('')
    const symptoms = ["Headache", "pulsing pain", "pain on right side", "dullness on right side", "numbness on right side", "neck tension", "Huyagonnacall", "pain in centre*", "dull pain","compression*","eye discomfort","tight muscles*","sholdier tension*"]


    useEffect(()=>{
        setText(service)
    },[service])

    //#console.log#'aspect',aspect)
   return (
        <View style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            {suds === -2?


                (
                    <Flex bg={"gray.100"} gap={10} mt={5} flexGrow={1} flexDirection={'column'} alignItems={'space-evenly'} >
                    <Box>
                        <Text>{header}</Text>
                    </Box>
                    <Box style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", margin: 10}}>

                        <Text mr={1} >{treatmentHeader[0]}</Text>
                        <Box maxW="500">
                            <Input variant={"underlined"} defaultValue={service} size={"md"} onChangeText={setText}/>
                            {/*
                                <Select selectedValue={service} minWidth="200" accessibilityLabel="Choose" placeholder="Choose" _selectedItem={{
                                    bg: "teal.600",

                                }} mt={1} onValueChange={itemValue => setService(itemValue)}>
                                    {symptoms.map(s=><Select.Item label={s} value={s} />)}
                                </Select>
                            */}
                        </Box>

                        <Text>{treatmentHeader[1]}</Text>
                        <Button
                            style={{marginLeft: 10}}
                            variant={"solid"}
                            isLoading={creatingTreatment}
                            spinnerPlacement="end"
                            disabled={creatingTreatment|| text.length<3}
                            leftIcon={<Icon as={MaterialIcons}  name='arrow-right'/>}
                            onPress={gotoSuds}
                            size={'md'}
                            p={3}
      
                            nativeID="btn-next-aspect"
       >Next</Button>

                    </Box>
                    </Flex>


):
            (


                <Flex bg={"gray.100"} gap={10} mt={5} flexGrow={1} flexDirection={'column'} alignItems={'space-evenly'} >

                    <Text>{treatmentHeader[0]} {text} {treatmentHeader[1]}</Text>

                    <Suds  navigation={navigation}  isLoading={creatingTreatment} submit={(val)=>{
                        createTreatment({name: text,suds: val})
                    }
                    }/>

                </Flex>
            )

           }
        </View>
    )
}


const TreatmentInput = ({navigation})=>{
    const {dispatch, treatmentData} = useAppContext()
    const [text,setText] = useState('')
    const [suds,setSuds] = useState(-2)
    const [loading,setLoading ] = useState(true)
    const [service,setService]= useState('')
    const symptoms = ["Headache", "pulsing pain", "pain on right side", "dullness on right side", "numbness on right side", "neck tension", "Huyagonnacall", "pain in centre*", "dull pain","compression*","eye discomfort","tight muscles*","sholdier tension*"]
    const [count,setCount] = useState(0)
    const [mod,setMod]=useState(false)
    const [video,setVideo]=useState('')

    useEffect(()=>{
            setVideo(treatmentData.video)

    },[treatmentData])
    useEffect(()=>{
        setText(service)
    },[service])
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
           // console.log('treatmentdone input',data)
            dispatch({type:'PROCESS_TREATMENT',data})
          ///  navigation.navigate(data.process)
        },
        onError: error => {
dispatch({type:'ERROR', error:true})
        }

    })

//        const pref='sss'
  //      const suf = 'sss'



   return (




         <Flex bg={"gray.100"} gap={2} mt={5} minH={500} flexGrow={1}  flexDirection={'column'} alignItems={'center'} justifyContent={'flex-start'}>

            <Box flexGrow={0}>
                <Text>{treatmentData.header}</Text>
            </Box>
             {treatmentData.header2 &&
             <Box padding={0} margin={0} flexGrow={0}>
                <Text>{treatmentData.header2}dddNever acquire the monkey, for you cannot study it.</Text>
            </Box>}
            <Box maxH={200} style={{flexGrow: 0 ,display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "flex-start", margin: 10}}>

            <Text mr={1}>{treatmentData.treatmentHeader}</Text>

             <Box maxH={100} py={5} minW={200}  width={{base:200, sm: 400}} flexGrow={0} alignItems={'center'} justifyContent={'flex-start'} >
              <Input nativeID="treatment"  w={'100%'} variant={"underlined"} defaultValue={service}  onChangeText={setText}/>
                 {/*

                     <Select selectedValue={service} minWidth="200" size={'lg'} accessibilityLabel="Choose" placeholder="Choose" _selectedItem={{
                        bg: "teal.600",

                    }} mt={1} onValueChange={itemValue => setService(itemValue)}>
                         {symptoms.map(s=><Select.Item label={s} value={s} />)}
                    </Select>
                     */}
                    </Box>


            <Text>{treatmentData.treatmentDescription}</Text>
                <Button
                    style={{marginLeft: 10}}
                    variant={"solid"}
                    isLoading={creatingTreatment}
                    spinnerPlacement="end"
                    disabled={creatingTreatment|| text.length<3}
                    leftIcon={<Icon as={MaterialIcons}  name='arrow-right'/>}
                onPress={e=>createTreatment({name:text})}
                p={3}
                    size={{ base:{width: 80, height: 40}, sm:{width: 100, height:50}}}
nativeID="btn-next-input"
>Next</Button>

        </Box>

             <Box flexGrow={1}  maxW={720} width={{md:'80%',base: '100%'}} flex={1}   mx={'auto'} >
                {video &&  <VideoPlayer url={video}/>}</Box>

         </Flex>





)
}
const Panic = ()=>{
    return (<></>)
}

const EndTaps = ({navigation,treatmentData,dispatch})=>{
    const [openImg,setOpenImg] = useState(false)
    const [openVideo,setOpenVideo] = useState(false)

    const [loading,setLoading ] = useState(true)
    const [maxVal,setMaxVal] = useState(10)
    const [step,setStep] = useState(1)
    const [showPanic,setShowPanic] = useState(false)
    const [images, setImages]=useState([])
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
           // console.log('treatment done',data)
            dispatch({type:'PROCESS_TREATMENT', data})

        },
        onError: error => {
dispatch({type:'ERROR', error:true})
        }

    })

    useEffect(()=>{
      //  console.log('ETaps Effect',treatmentData.images,'id',treatmentData.id)
        setImages([treatmentData.images[0]+'?'+Math.random(),treatmentData.images[1]])
    },[treatmentData.id])
    const setNextStep= useCallback(()=>{
        createTreatment({})

    },[createTreatment])


    const topRef = useRef()
    const imgRef = useRef()


    return (
        <View
            ref={topRef}
        >
            <View
                display={'flex'}

                flexGrow={1}
                flexShrink={0}
                flexWrap={'no-wrap'}
                flexDirection={{base: 'column', md: 'row'}}
                w="100%"
                bg={'cyan.200'}


                justifyContent={'space-around'}
                alignItems={'stretch'}
                alignContent={'stretch'}
                justifyItems={'space-around'}
            >


                <Box display={'inline-block'}   flexGrow={1} bg={'green.200'} flexShrink={0}  justifyContent={'center'} flexBasis={'50%'} height={{base: '330px',md: '100%'}} >


                    <Flex  flexDir={'column'} alignItems={'stretch'} justifyContent={'space-evenly'}   flex={1} flexDirection={'column'} h={'100%'}>
                        <Text variant={'h2'} color={useColorModeValue('blue.500', 'green.100')}>
                            {treatmentData.problem}
                        </Text>

                        <Head color={useColorModeValue('blueGray.700', 'blueGray.100')}>
                            {treatmentData.treatmentHeader}
                        </Head>

                        <Text variant={'body'} color={useColorModeValue('blueGray.500', 'blueGray.200')}>
                            {treatmentData.treatmentDescription}

                        </Text>


                        <HStack justifyContent={'flex-end'} gap={5} mr={5} space={4}>
                            {treatmentData.level > 6000 && treatmentData.level < 11
                            &&
                            <Button
                                style={{marginLeft: 10}}
                                variant={"solid"}
                                colorScheme={"primary"}
                                isLoading={creatingTreatment}
                                spinnerPlacement="end"
                                disabled={creatingTreatment}
                                leftIcon={<Icon as={MaterialIcons} name='arrow-right'/>}
                                onPress={() => {
                                    createTreatment({level: 11})
                                }}

                            >Call GHOSTBUSTER</Button>
                            }




                            <Button variant="solid" colorScheme="blue" p={0} minH={0} onPress={setNextStep}
                                    rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'md'}/>}
                                    ml={1} p={3}
                                    size={'md'}
                                    nativeID="btn-next-etap"
                                    isLoading={creatingTreatment}
                                    isDisabled={creatingTreatment}
                            >
                                Next
                            </Button>
                        </HStack>
                    </Flex>

                </Box>
                  <Flex   flexGrow={1} flexBasis={'50%'} flexShrink={0}  bg={'yellow.300'}    >
                    <AspectRatio ratio={2/1}>
                        <Link
                            onPress={()=>{
                                dispatch({type: 'IMAGE',data:{imgUrl: images[0], imgTitle: treatmentData.imgTitle}})
                                //  setOpenVideo(true)
                                //topRef.current.scrollTo({x:0,y:100,animated:false})
                            }}>

                            <Image
                                width={'100%'}
                                source={{
                                    uri: images[0],
                                }}
                                alt={treatmentData.imgTitle??'Title'}
                            />
                        </Link>
                    </AspectRatio>
                    <AspectRatio ratio={2/1}>
                        <Link
                            onPress={()=>{
                                dispatch({type: 'VIDEO',data:{videoUrl: treatmentData.video, videoTitle: treatmentData.videoTitle}})
                                //  setOpenVideo(true)
                                // topRef.current.scrollTo(0,0)
                                //#console.log#'scrolled')
                            }}>
                            <Image

                                ref={imgRef}
                                width={'100%'}
                                source={{
                                    uri: images[1],
                                }}
                                alt={treatmentData.videoTitle??'NoVideo'}
                            />
                        </Link>
                    </AspectRatio>

                </Flex>


            </View>
        </View>




    )



}
const SameName = ({navigation,treatmentData,dispatch})=>{
    const [loading,setLoading ] = useState(true)
    const [maxVal,setMaxVal] = useState(10)
    const [step,setStep] = useState(1)
    const [showPanic,setShowPanic] = useState(false)
    const [conf,setConf] = useState('')
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
            //   console.log('treatment done',data)
            dispatch({type:'PROCESS_TREATMENT', data})

        },
        onError: error => {
dispatch({type:'ERROR', error:true})
        }

    })

    useEffect(()=>{
        // console.log('Taps Effect',treatmentData.images)
        //    setImages([treatmentData.images[0],treatmentData.images[1]])
    },[treatmentData])
    const setNextStep= useCallback(()=>{
        createTreatment({conf})

    },[])


    return (

        <View
            display={'flex'}

            flexGrow={1}
            flexShrink={0}
            flexWrap={'no-wrap'}
            flexDirection={{base: 'column', md: 'row'}}
            w="100%"
            bg={'cyan.200'}


            justifyContent={'space-around'}
            alignItems={'stretch'}
            alignContent={'stretch'}
            justifyItems={'space-around'}
        >


            <Box display={'inline-block'}   flexGrow={1} bg={'green.200'} flexShrink={0}  justifyContent={'center'} flexBasis={'50%'} height={{base: '330px',md: '100%'}} >


                <Flex  flexDir={'column'} alignItems={'stretch'} justifyContent={'space-evenly'}   flex={1} flexDirection={'column'} h={'100%'}>
                    <Text variant={'h2'} color={useColorModeValue('blue.500', 'green.100')}>
                        {treatmentData.problem}
                    </Text>

                    <Head color={useColorModeValue('blueGray.700', 'blueGray.100')}>
                        {treatmentData.treatmentHeader}
                    </Head>

                    <Text variant={'body'} color={useColorModeValue('blueGray.500', 'blueGray.200')}>
                        {treatmentData.treatmentDescription}

                    </Text>


                    <HStack justifyContent={'flex-end'} gap={5} mr={5} space={4}>
                        <Button variant="solid" colorScheme="blue" p={0} minH={0} onPress={e=>{createTreatment({samename:'no'})}}
                                rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'lg'}/>}
                                ml={1}
                                p={3}
                                size={'md'}
                            isLoading={creatingTreatment}
                                isDisabled={creatingTreatment}
                nativeID="btn-same-no"
>
                            No
                        </Button>

                        <Button variant="solid" colorScheme="blue" p={0} minH={0} onPress={e=>{createTreatment({samename:'yes'})}}
                                rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'lg'}/>}
                                ml={1}
                                p={3}
                                size={'md'}
                        isLoading={creatingTreatment}
                                isDisabled={creatingTreatment}
nativeID="btn-same-yes"
>
                            Yes
                        </Button>
                    </HStack>
                </Flex>

            </Box>


        </View>
    )

}

const Confirm = ({navigation,treatmentData,dispatch})=>{
    const [loading,setLoading ] = useState(true)
    const [maxVal,setMaxVal] = useState(10)
    const [step,setStep] = useState(1)
    const [showPanic,setShowPanic] = useState(false)
    const [conf,setConf] = useState('')
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
            //   console.log('treatment done',data)
            dispatch({type:'PROCESS_TREATMENT', data})

        },
        onError: error => {
            console.log('ERROR',error)
dispatch({type:'ERROR', error:true})
        }

    })

    useEffect(()=>{
        // console.log('Taps Effect',treatmentData.images)
        //    setImages([treatmentData.images[0],treatmentData.images[1]])
    },[treatmentData])
    const setNextStep= useCallback(()=>{
        createTreatment({conf})

    },[conf])


    return (

        <View
            display={'flex'}

            flexGrow={1}
            flexShrink={0}
            flexWrap={'no-wrap'}
            flexDirection={{base: 'column', md: 'row'}}
            w="100%"
            bg={'cyan.200'}


            justifyContent={'space-around'}
            alignItems={'stretch'}
            alignContent={'stretch'}
            justifyItems={'space-around'}
        >


            <Box display={'inline-block'}   flexGrow={1} bg={'green.200'} flexShrink={0}  justifyContent={'center'} flexBasis={'50%'} height={{base: '330px',md: '100%'}} >


                <Flex  flexDir={'column'} alignItems={'stretch'} justifyContent={'space-evenly'}   flex={1} flexDirection={'column'} h={'100%'}>
                    <Text variant={'h2'} color={useColorModeValue('blue.500', 'green.100')}>
                        {treatmentData.problem}
                    </Text>

                    <Head color={useColorModeValue('blueGray.700', 'blueGray.100')}>
                        {treatmentData.treatmentHeader}
                    </Head>

                    <Text variant={'body'} color={useColorModeValue('blueGray.500', 'blueGray.200')}>
                        {treatmentData.treatmentDescription}

                    </Text>


                    <HStack justifyContent={'flex-end'} gap={5} mr={5} space={4}>
                        <Button variant="solid" colorScheme="blue" p={0} minH={0} onPress={e=>{createTreatment({sure:'no'})}}
                                rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'md'}/>}
                                ml={1}
                                p={3}
                                size={'md'}
                                isLoading={creatingTreatment}
                                isDisabled={creatingTreatment}
                                nativeID="btn-sure-no"
>

                        
                            No
                        </Button>

                        <Button variant="solid" colorScheme="blue" p={0} minH={0} onPress={e=>{createTreatment({sure:'yes',suds:0})}}
                                rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'md'}/>}
                                ml={1}
                                p={3}
                                size={'md'}
                                    isLoading={creatingTreatment}
nativeID="btn-sure-yes"
                                isDisabled={creatingTreatment}
                        >
                            Yes
                        </Button>
                    </HStack>
                </Flex>

            </Box>


        </View>
    )

}

const Complete = ({navigation,treatmentData,dispatch})=>{
    const [loading,setLoading ] = useState(true)
    const [maxVal,setMaxVal] = useState(10)
    const [step,setStep] = useState(1)
    const [showPanic,setShowPanic] = useState(false)
    const [conf,setConf] = useState('')
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
            //   console.log('treatment done',data)
            dispatch({type:'PROCESS_TREATMENT', data})

        },
        onError: error => {
dispatch({type:'ERROR', error:true})
        }

    })

    useEffect(()=>{
        // console.log('Taps Effect',treatmentData.images)
        //    setImages([treatmentData.images[0],treatmentData.images[1]])
    },[treatmentData])
    const setNextStep= useCallback(()=>{
        createTreatment({conf})

    },[])


    return (

        <View
            display={'flex'}

            flexGrow={1}
            flexShrink={0}
            flexWrap={'no-wrap'}
            flexDirection={{base: 'column', md: 'row'}}
            w="100%"
            bg={'cyan.200'}


            justifyContent={'space-around'}
            alignItems={'stretch'}
            alignContent={'stretch'}
            justifyItems={'space-around'}
        >


            <Box display={'inline-block'}   flexGrow={1} bg={'green.200'} flexShrink={0}  justifyContent={'center'} flexBasis={'50%'} height={{base: '330px',md: '100%'}} >


                <Flex  flexDir={'column'} alignItems={'stretch'} justifyContent={'space-evenly'}   flex={1} flexDirection={'column'} h={'100%'}>
                    <Text variant={'h2'} color={useColorModeValue('blue.500', 'green.100')}>
                        {treatmentData.problem}
                    </Text>

                    <Head color={useColorModeValue('blueGray.700', 'blueGray.100')}>
                        {treatmentData.treatmentHeader}
                    </Head>

                    <Text variant={'body'} color={useColorModeValue('blueGray.500', 'blueGray.200')}>
                        {treatmentData.treatmentDescription}

                    </Text>


                    <HStack justifyContent={'flex-end'} gap={5} mr={5} space={4}>
                        <Button variant="solid" colorScheme="blue" p={0} minH={0} onPress={e=>{createTreatment({ complete :'no'})}}
                                rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'lg'}/>}
                                ml={1}
                                p={3}
                                size={'md'}
                                nativeID="btn-complete-no"
                                isLoading={creatingTreatment}
                        >
                            No
                        </Button>

                        <Button variant="solid" colorScheme="blue" p={0} minH={0} onPress={e=>{createTreatment({ complete:'yes'})}}
                                rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'lg'}/>}
                                ml={1}
                                nativeID="btn-complete-yes"
                                p={3}
                                size={'md'}
                            isLoading={creatingTreatment}
                        >
                            Yes
                        </Button>
                    </HStack>
                </Flex>

            </Box>


        </View>
    )

}

const Cc = ({navigation,treatmentData,dispatch})=>{
    const [loading,setLoading ] = useState(true)
    const [maxVal,setMaxVal] = useState(10)
    const [step,setStep] = useState(1)
    const [showPanic,setShowPanic] = useState(false)
    const [conf,setConf] = useState('')
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
            //   console.log('treatment done',data)
            dispatch({type:'PROCESS_TREATMENT', data})

        },
        onError: error => {
dispatch({type:'ERROR', error:true})
        }

    })

    useEffect(()=>{
        // console.log('Taps Effect',treatmentData.images)
    //    setImages([treatmentData.images[0],treatmentData.images[1]])
    },[treatmentData])
    const setNextStep= useCallback(()=>{
        createTreatment({conf})

    },[conf, createTreatment])


    return (

            <View
                display={'flex'}

                flexGrow={1}
                flexShrink={0}
                flexWrap={'no-wrap'}
                flexDirection={{base: 'column', md: 'row'}}
                w="100%"
                bg={'cyan.200'}


                justifyContent={'space-around'}
                alignItems={'stretch'}
                alignContent={'stretch'}
                justifyItems={'space-around'}
            >


                <Box display={'inline-block'}   flexGrow={1} bg={'green.200'} flexShrink={0}  justifyContent={'center'} flexBasis={'50%'} height={{base: '330px',md: '100%'}} >


                    <Flex  flexDir={'column'} alignItems={'stretch'} justifyContent={'space-evenly'}   flex={1} flexDirection={'column'} h={'100%'}>
                        <Text variant={'h2'} color={useColorModeValue('blue.500', 'green.100')}>
                            {treatmentData.problem}
                        </Text>

                        <Head color={useColorModeValue('blueGray.700', 'blueGray.100')}>
                            {treatmentData.treatmentHeader}
                        </Head>

                        <Text variant={'body'} color={useColorModeValue('blueGray.500', 'blueGray.200')}>
                            {treatmentData.treatmentDescription}

                        </Text>


                        <HStack justifyContent={'flex-end'} gap={5} mr={5} space={4}>
                            <Button variant="solid" colorScheme="blue" p={0} minH={0} onPress={e=>{createTreatment({sure:'no'})}}
                                    rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'lg'}/>}
                                    ml={1}
                                    p={3}
                                    size={'md'}
                            >
                                No
                            </Button>

                            <Button variant="solid" colorScheme="blue" p={0} minH={0} onPress={e=>{createTreatment({sure:'yes',suds:0})}}
                                    rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'lg'}/>}
                                    ml={1}
                                    p={3}
                                    size={'md'}
                            >
                                Yes
                            </Button>
                        </HStack>
                    </Flex>

                </Box>


            </View>
    )

}
const Taps = ({navigation,treatmentData,dispatch})=>{
    const [openImg,setOpenImg] = useState(false)
    const [openVideo,setOpenVideo] = useState(false)

    const [loading,setLoading ] = useState(true)
    const [maxVal,setMaxVal] = useState(10)
    const [step,setStep] = useState(1)
    const [showPanic,setShowPanic] = useState(false)
    const [images, setImages]=useState([])
    const { mutate: createTreatment, isLoading: creatingTreatment } = useMutation(createTreatmentPost, {
        onSuccess: data => {
         //   console.log('treatment done',data)
            dispatch({type:'PROCESS_TREATMENT', data})

        },
        onError: error => {
dispatch({type:'ERROR', error:true})
        }

    })

    useEffect(()=>{
       // console.log('Taps Effect',treatmentData.images)
        setImages(treatmentData.images)
    },[treatmentData])
    const setNextStep= useCallback(()=>{
        createTreatment({})

    },[])


    const topRef = useRef()
    const imgRef = useRef()
    const colorh = useColorModeValue('blueGray.600', 'blueGray.100')


    return (
        <View
            ref={topRef}
        >
            <View
                display={'flex'}

                flexGrow={1}
                flexShrink={0}
                flexWrap={'no-wrap'}
                flexDirection={{base: 'column', md: 'row'}}
                w="100%"
                bg={'cyan.200'}


                justifyContent={'space-around'}
                alignItems={'stretch'}
                alignContent={'stretch'}
                justifyItems={'space-around'}
            >


                <Box display={'inline-block'}   flexGrow={1} bg={'green.200'} flexShrink={0}  justifyContent={'center'} flexBasis={'50%'} height={{base: '330px',md: '100%'}} >


                    <Flex  flexDir={'column'} alignItems={'stretch'} justifyContent={'space-evenly'}   flex={1} flexDirection={'column'} h={'100%'}>
                        <Text variant={'h2'} color={useColorModeValue('blue.500', 'green.100')}>
                            {treatmentData.problem}
                        </Text>

                        {treatmentData.header2 &&<Head color={colorh}>
                            {treatmentData.header2}
                        </Head> }
                        <Head color={useColorModeValue('blueGray.700', 'blueGray.100')}>
                            {treatmentData.treatmentHeader}
                        </Head>
                        <Text variant={'body'} color={useColorModeValue('blueGray.500', 'blueGray.200')}>
                            {treatmentData.treatmentDescription}
                        </Text>


                        <HStack justifyContent={'flex-end'} gap={5} mr={5} space={4}>
                            <Button variant="solid" colorScheme="blue" p={0} minH={0} onPress={setNextStep}
                                    rightIcon={<Icon as={MaterialIcons} name='arrow-right' size={'md'}/>}
                                    ml={1}
                                    p={3}
                                    size={'md'}
                                    isLoading={creatingTreatment}
                                    isDisabled={creatingTreatment}
        nativeID="btn-next-tap"
>
                                Next
                            </Button>
                        </HStack>
                    </Flex>

                </Box>
                <Flex   flexGrow={1} flexBasis={'50%'} flexShrink={0}  bg={'yellow.300'}    >
{images.length>0 &&                    <AspectRatio ratio={2/1}>
                        <Link
                            onPress={()=>{
                                dispatch({type: 'IMAGE',data:{imgUrl: images[0], imgTitle: treatmentData.imgTitle}})
                                //  setOpenVideo(true)
                                //topRef.current.scrollTo({x:0,y:100,animated:false})
                            }}>

                            <Image
                                width={'100%'}
                                source={{
                                    uri: images[0],
                                }}
                                alt={treatmentData.imgTitle??'Title'}
                            />
                        </Link>
                    </AspectRatio>
}
{images.length>1 &&                    <AspectRatio ratio={2/1}>

                        <Link
                            onPress={()=>{
                                dispatch({type: 'VIDEO',data:{videoUrl: treatmentData.video, videoTitle: treatmentData.videoTitle}})
                                //  setOpenVideo(true)
                                // topRef.current.scrollTo(0,0)
                                //#console.log#'scrolled')
                            }}>
                            <Image

                                ref={imgRef}
                                width={'100%'}
                                source={{
                                    uri: images[1],
                                }}
                                alt={treatmentData.videoTitle??'NoVideo'}
                            />
                        </Link>
                    </AspectRatio>
}
                </Flex>


            </View>
        </View>




    )



}


const PanicScreen = ({navigation})=>{

    return    (
        <Box>
            <Text>Panic</Text>
            <Button onPress={() => navigation.pop()} title="Dismiss" variant={"solid"}>Back</Button>

        </Box>
    )
}
const showProcess=(data)=>{
    const proc = data['process']==='Impediments'? '': ' > '+data['process']
    if(data['process']=== data['mainprocess']){
        return data['process']
    }
    return data['mainprocess']+ proc

}
// eslint-disable-next-line no-unused-vars
const TreatmentHeader = ({navigation,treatmentData,setNextStep,showSuds})=>{ //thead
    const  {header,remaining,problem,askSuds,showPanic, aspect, round,attempt,level,sub,aspectof }= treatmentData

    useEffect(()=>{
            navigation.setOptions({
                    headerLeft: null,
                    title: treatmentData.header + '  |  ' + showProcess(treatmentData) + ' | ' + level+'|'+sub,

                }
            )

        },

        [level, navigation, sub, treatmentData]

    )

    return (
        <Box style={{display: "flex", flexDirection: "column"}}>
        <Box>{header}    <>{problem}</></Box>

            <Box style={{display: "flex", flexDirection: "row"}}>

                {treatmentData.process=== 'Taps'&& <Box mr={5}><Text>Attempts: {(attempt+1)}</Text></Box>}


                {Object.keys(aspect).length>1 && <>
                <Box><Text bold>Aspect Of {aspectof}&nbsp;</Text></Box>
                <Box style={{display: "flex", flexDirection: "row", backgroundColor: "yellow"}}>{Object.keys(aspect).map( s=> {
                     return    <Box key={s} mr={2}><Text>{s}&nbsp;{aspect[s]}</Text></Box>

                    }
                )}
                </Box>
            </>}
            </Box>


            </Box>
    )

}

 const TreatmentScreen = ({navigation}) => {
    const [openImg,setOpenImg] = useState(false)
    const [openVideo,setOpenVideo] = useState(false)
    const [process,setProcess]=useState('')
     const [subprocess,setSubProcess]=useState('')
    const topRef = useRef()
    const imgRef = useRef()
    const { dispatch,treatmentData } = useAppContext()
    useFocusEffect(()=>{
      //  console.log('treatmenscreentfocus',treatmentData)

        return ()=>{

            //#console.log#'outoffocus')
        }

    })
    useEffect(()=>{
        if(!treatmentData){
            return
        }
        //console.log('treatmentprocessor2',treatmentData.process)
        if(!treatmentData.process){

        }
        console.log('settingprocess',treatmentData.process)
        setProcess(treatmentData.process)
    },[treatmentData, treatmentData.process])


     const colorMode = useColorMode()

     return (

         <Content>

            <Divider opacity={colorMode === 'dark' ? '0.4' : '1'} my={0} py={0} h={2} flexGrow={0} flexShrink={0}/>
            <View padding={5}  bg={'#BBF7D0'}>
               <View nativeID="remaining">{treatmentData.h1 &&<Text>Remaining {treatmentData.problem}</Text>}</View>
             {/*     <Flex flexGrow={1} flexShrink={0} flexDirection={'column'} alignItems={'stretch'} justifyContent={'center'} bg={'yellow.50'}>
            */}
            <>
                {(()=>{

                 if(!process){
                     return <Initialize dispatch={dispatch} treatmentData={treatmentData}/>
                 }

                 if(process==='input'){
                    console.log('input')

                    return <TreatmentInput navigation={navigation}/>

                 }
                 if(process === 'suds'){
                     return <SudsView navigation={navigation} />
                 }
                 if(process === 'tap'){

                     return <Taps navigation={navigation} treatmentData={treatmentData} dispatch={dispatch} />
                 }
                 if (['etap','tat','gamut','psyrev','collarbone','shower','fast','changepos'].includes(treatmentData.process)){
                    return (
                    <Impediments navigation={navigation} treatmentData={treatmentData} dispatch={dispatch}/>
                    )

                 }


                 if(treatmentData.process === 'ghostbuster') {

                         return(


                             <GhostBuster navigation={navigation} treatmentData={treatmentData} dispatch={dispatch} />

                         )
                     }
                    if(treatmentData.process === 'complete') {

                        return(


                            <Complete navigation={navigation} treatmentData={treatmentData} dispatch={dispatch}/>

                        )
                    }


                    if(treatmentData.process === 'report') {

                     return(


                         <Finish navigation={navigation} treatmentData={treatmentData} dispatch={dispatch}/>

                     )
                 }
                 if(treatmentData.process === 'stuck') {

                     return(


                         <Stuck navigation={navigation} treatmentData={treatmentData} dispatch={dispatch}/>

                     )
                 }
                 if(treatmentData.process === 'etap') {

                     return(



                         <EndTaps navigation={navigation} treatmentData={treatmentData} dispatch={dispatch} />

                     )
                 }

                 if(treatmentData.process === 'aspect1') {

                     return(


                         <Aspect navigation={navigation} treatmentData={treatmentData} dispatch={dispatch}/>

                     )
                 }

                 else if(process==='aspect'){

                     return <Confirm navigation={navigation} treatmentData={treatmentData} dispatch={dispatch} />
                 }

                 else if(process==='samename'){

                     return <SameName navigation={navigation} treatmentData={treatmentData} dispatch={dispatch} />
                 }


             })()

             }
            </>
                <ErrorModal/>
                <ImageModalSheet/>
                <VideoModalSheet/>
            </View>

         </Content>
     )



}

export  {TreatmentScreen, PanicScreen}
