import React, {useRef, useState} from 'react';
import {
    Box,
    Text,
    Image,
    Heading,
    Link,
    Stack,
    Flex,
    VStack,
    useColorModeValue,
    HStack,
    Button,
    View, AspectRatio, Divider, ScrollView, Alert, Center, IconButton, Spinner
} from 'native-base';

import {VideoModal} from "./Components";
import {useAppContext, useText } from "./AppContextProvider";


export const Composition = ({navigation}) => {
    const [openImg,setOpenImg] = useState(false)
    const [openVideo,setOpenVideo] = useState(false)
    const {dispatch } = useAppContext()
    const { isLoading, isError, data, isSuccess } = useText('start')

    const topRef = useRef()
    const imgRef = useRef()
    const cl = useColorModeValue('blueGray.700', 'blueGray.100')
    if(isLoading||!isSuccess){
        return <Spinner size={'lg'}/>
    }
    if(isError){
        return <Text>Error</Text>
    
    }
const { header, treatementDescription, video ,  pageHeader, terms, images, button, link } = data


    return (
        <View
            ref={topRef}
        >
        <View
            display={'flex'}

            flexGrow={1}
            flexShrink={0}
            flexWrap={'no-wrap'}
            flexDirection={{base: 'column', md: 'row'}}
            w="100%"
            bg={'gray.200'}


            justifyContent={'space-around'}
            alignItems={'stretch'}
            alignContent={'stretch'}
            justifyItems={'space-around'}
        >


            <Box display={'inline-block'}   flexGrow={1} bg={'amber.100'} flexShrink={0}  justifyContent={'center'} flexBasis={'50%'} height={{base: '330px',md: '100%'}} >


        <Flex flexDir={'column'}  bg={'green.200'} flex={1} flexDirection={'column'} h={'100%'}>

                <Heading color={{cl}}>
                    {header}
                </Heading>
                <Text color={cl}>
                                    {treatementDescription}
                                    </Text>


            <HStack space={4}>
               <Button nativeID='btn-start' variant="ghost" colorScheme="blue" p={0} minH={0} onPress={()=>{
                navigation.navigate('Treatment')
               }}>
                    {button}
                </Button>
            </HStack>
           <Box flexGrow={1}>
             <Center>
               <Alert maxW="400" status="info" colorScheme="info">
                   <VStack space={2} flexShrink={1} w="100%">
                       <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                           <HStack flexShrink={1} space={2} alignItems="center">
                               <Alert.Icon />
                               <Text fontSize="md" fontWeight="medium" color="coolGray.800">
                                {pageHeader}
                               </Text>
                           </HStack>

                       </HStack>
                       <Box pl="6" _text={{
                           color: "coolGray.600"
                       }}><Text>{terms}</Text>
                            <Link onPress={e=>{
                                navigation.navigate('Page',{id: link})
                            }
                            } _text={{
                                color: "blue.500"
                            }
                            } href={'#'} >More..</Link>
                       </Box>
                   </VStack>
               </Alert>
           </Center>



           </Box>
            </Flex>

            </Box>
        <Flex   flexGrow={1} flexBasis={'50%'} flexShrink={0}  bg={'yellow.300'}    >
            <AspectRatio ratio={2/1}>
                <Link
                    onPress={()=>{
                        dispatch({type: 'IMAGE',data:{imgUrl: images[0], imgTitle: 'Test Image'}})
                        //  setOpenVideo(true)
                        //topRef.current.scrollTo({x:0,y:100,animated:false})
                    }}>

                <Image
               width={'100%'}
               source={{
                    uri: images[0]}}
                alt="image title"
            />
                </Link>
            </AspectRatio>
            <AspectRatio ratio={2/1}>
               <Link
                   onPress={()=>{
                  dispatch({type: 'VIDEO',data:{videoUrl: video, videoTitle: ''}})
                   //  setOpenVideo(true)
                  // topRef.current.scrollTo(0,0)
                    //#console.log#'scrolled')
               }}>
                <Image

                    ref={imgRef}
                    width={'100%'}
                    source={{
                        uri: images[1],
                    }}
                    alt="image video title"
                />
               </Link>
            </AspectRatio>

        </Flex>


        </View>
        </View>




    )
}
